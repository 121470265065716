const EyeCloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25749_1667)">
                <path opacity="0.2" d="M12 5.25C4.5 5.25 1.5 12 1.5 12C1.5 12 4.5 18.75 12 18.75C19.5 18.75 22.5 12 22.5 12C22.5 12 19.5 5.25 12 5.25ZM12 15.75C11.2583 15.75 10.5333 15.5301 9.91661 15.118C9.29993 14.706 8.81928 14.1203 8.53545 13.4351C8.25162 12.7498 8.17736 11.9958 8.32205 11.2684C8.46675 10.541 8.8239 9.8728 9.34835 9.34835C9.8728 8.8239 10.541 8.46675 11.2684 8.32205C11.9958 8.17736 12.7498 8.25162 13.4351 8.53545C14.1203 8.81928 14.706 9.29993 15.118 9.91661C15.5301 10.5333 15.75 11.2583 15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75Z" fill="#637381" />
                <path d="M4.5 3.75L19.5 20.25" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.5228 14.775C13.7868 15.4441 12.8152 15.7934 11.8217 15.7461C10.8281 15.6988 9.89409 15.2588 9.225 14.5228C8.55591 13.7868 8.20659 12.8152 8.25388 11.8217C8.30117 10.8281 8.74121 9.89407 9.47719 9.22498" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.7059 8.31653C13.5032 8.4692 14.2294 8.87644 14.7754 9.47708C15.3214 10.0777 15.6578 10.8393 15.7341 11.6475" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.5572 15.8531C21.601 14.0231 22.5 12 22.5 12C22.5 12 19.5 5.25001 12 5.25001C11.3505 5.24912 10.7021 5.3018 10.0613 5.40751" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.9375 6.43127C3.11531 8.36627 1.5 12 1.5 12C1.5 12 4.5 18.75 12 18.75C13.7574 18.7638 15.4927 18.3589 17.0625 17.5688" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_25749_1667">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EyeCloseIcon;