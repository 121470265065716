const PkbLocationIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_26072_1605)">
                <path opacity="0.2" d="M12.3335 21.6925C12.3197 21.6826 12.3007 21.6689 12.2769 21.6515C12.2222 21.6116 12.1419 21.5521 12.0397 21.474C11.8352 21.3177 11.5429 21.0867 11.192 20.7885C10.4902 20.192 9.55445 19.3264 8.61887 18.2505C6.74655 16.0973 4.88037 13.1081 4.88037 9.75C4.88037 7.77331 5.66561 5.87758 7.06334 4.47984C8.46107 3.08211 10.3568 2.29688 12.3335 2.29688C14.3102 2.29688 16.2059 3.08211 17.6037 4.47984C19.0014 5.87758 19.7866 7.77331 19.7866 9.75C19.7866 13.1081 17.9204 16.0973 16.0481 18.2505C15.1125 19.3264 14.1768 20.192 13.475 20.7885C13.1241 21.0867 12.8318 21.3177 12.6273 21.474C12.525 21.5521 12.4448 21.6116 12.3901 21.6515C12.3663 21.6689 12.3473 21.6826 12.3335 21.6925ZM10.6407 12.2834C11.1418 12.6182 11.7309 12.7969 12.3335 12.7969C13.1416 12.7969 13.9166 12.4759 14.488 11.9045C15.0594 11.3331 15.3804 10.5581 15.3804 9.75C15.3804 9.14739 15.2017 8.5583 14.8669 8.05725C14.5321 7.55619 14.0562 7.16566 13.4995 6.93505C12.9427 6.70444 12.3301 6.64411 11.7391 6.76167C11.148 6.87923 10.6051 7.16942 10.179 7.59553C9.75292 8.02165 9.46273 8.56455 9.34517 9.15558C9.2276 9.74662 9.28794 10.3592 9.51855 10.916C9.74916 11.4727 10.1397 11.9486 10.6407 12.2834Z" fill="#637381" stroke="#637381" stroke-width="0.09375" />
                <path d="M12.3335 12.75C13.9904 12.75 15.3335 11.4069 15.3335 9.75C15.3335 8.09315 13.9904 6.75 12.3335 6.75C10.6766 6.75 9.3335 8.09315 9.3335 9.75C9.3335 11.4069 10.6766 12.75 12.3335 12.75Z" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.8335 9.75C19.8335 16.5 12.3335 21.75 12.3335 21.75C12.3335 21.75 4.8335 16.5 4.8335 9.75C4.8335 7.76088 5.62367 5.85322 7.0302 4.4467C8.43672 3.04018 10.3444 2.25 12.3335 2.25C14.3226 2.25 16.2303 3.04018 17.6368 4.4467C19.0433 5.85322 19.8335 7.76088 19.8335 9.75Z" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_26072_1605">
                    <rect width="24" height="24" fill="white" transform="translate(0.333496)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PkbLocationIcon;