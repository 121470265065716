import React, { useState, useEffect, useRef } from 'react';

const PkbBannerSlideshow = (props) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const slideRef = useRef(null)
    const plusSlides = (n) => {
        let newIdx = slideIndex + n > (props?.size - 1) ? 0 :  slideIndex + n < 0 ? (props?.size - 1) : slideIndex + n;

        setSlideIndex(newIdx)
        //showSlides(newIdx);
    }

    const currentSlide = (n) => {
        setSlideIndex(n)
        showSlides(n);
    }
    useEffect(() => {
        showSlides(slideIndex)
    }, [slideIndex])

    useEffect(() => {
        setSlideIndex(0)
    }, [])

    const showSlides = (n) => {
        
        let i;
        let slides = slideRef.current

        for (i = 0; i < slides.childElementCount; i++) {
            slides.children[i].style.display = "none";
        }
        // for (i = 0; i < dots.length; i++) {
        //     dots[i].className = dots[i].className.replace(" active", "");
        // }
        

        if (!slides.children[n])  { return }

        slides.children[n].style.display = "block";
        
        //dots[slideIndex - 1].className += " active";
    }
    return (
        <div   style={{...styles.slideshowContainer, ...props.containerStyle}}>
            <div ref={slideRef} className="w-full h-full">
            {props?.data?.length ?
                props?.data.map((image, idx) => {
                    return (
                        <div className="mySlides"  style={{...styles.slideContainer}}>
                            <img src={image.publicPath + image.avatar} style={{ width: '100%', height: '100%', ...props.imgStyle }} />
                        </div>
                    )
                })
                : <></>}
            </div>
            
            <a style={{...styles.slideButton, left: 0}} onClick={() => plusSlides(-1)}>&#10094;</a>
            <a style={{...styles.slideButton, right: 0}} onClick={() => plusSlides(1)}>&#10095;</a>
        </div>
    )
};

const styles = {
    slideshowContainer: {
        width: '100%',
        position: 'relative',
        margin: 'auto',
        height: 543,
        overflow: 'hidden'
    },
    slideContainer: {
        //transform: 'translateX(100%)',
        transition: 'transform 0.5s ease',
    },
    slideImage: {
        position: 'absolute',
        width: '100%',
        transform: 'translateX(100%)',
        opacity: 0,
        borderRadius: '8px',
    },
    slideButton: {
        cursor: 'pointer',
       position: 'absolute',
        top: '50%',
       // width: 'auto',
        padding: '16px',
        marginTop: '- 22px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '18px',
        transition: '0.6s ease',
        borderRadius: '0 3px 3px 0',
        userSelect: 'none',
        height: 50,
        width:50,
    },
    nextButton: {
        cursor: 'pointer',
        position: 'absolute',
        top: '50 %',
        width: 'auto',
        padding: '16px',
        marginTop: '- 22px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '18px',
        transition: '0.6s ease',
        borderRadius: '0 3px 3px 0',
        userSelect: 'none',
    },
    dots: {
        textAlign: 'center',
        marginTop: '10px',
    },
    dot: {
        height: '10px',
        width: '10px',
        margin: '0 5px',
        backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block',
        cursor: 'pointer',
    },
};

export default PkbBannerSlideshow;
