import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PkbHeaderAction from "./header-action";
import PkbHeaderUser from "./header-user";
import PkbLogo from "./logo";


const listPage = [
    {
        id: 1,
        title: 'Trang chủ',
        url: '/'
    },
    {
        id: 2,
        title: 'Giới thiệu',
        url: '/tournament/detail'
    },
    {
        id: 3,
        title: 'Giải đấu',
        url: '/tournament'
    },
    {
        id: 4,
        title: 'Tin tức',
        url: '/news'
    },
    {
        id: 5,
        title: 'Câu lạc bộ',
        url: '/'
    },
    {
        id: 6,
        title: 'Shop',
        url: '/'
    },
    {
        id: 7,
        title: 'Liên hệ',
        url: '/'
    }
]
const PkbHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const pageId = useSelector((state) => state.pageId)

    const onChangePage = (id, url) => {
        if (id === pageId) { 
            return;
        }
        navigate(url)
    }

    useEffect(() => {
        const pid = listPage.find( x => x.url === location.pathname );

        dispatch({ type: 'set', pageId: pid?.id || 1 })
    }, [location])
    return (
        <>
            <div className="grid grid-flow-col rounded-2xl h-16 mx-[60px] text-base bg-white px-4 pkb-primary-shadow">
                <div className="flex items-center">
                    <PkbLogo></PkbLogo>
                </div>
                <div className="flex items-center">
                    <nav className="space-x-10 cursor-pointer ">
                        {listPage.map(item => {
                            return (
                                <>
                                    <a className="hover:no-underline" onClick={() => onChangePage(item.id, item.url)}>
                                        <span className={pageId === item.id ? 'pkb-tab-active' : ''}><svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline">
                                            <circle cx="4.28809" cy="4" r="4" fill={pageId === item.id ? '#0162AC' : '#fff'} />
                                        </svg> {item.title}</span>
                                    </a>
                                </>
                            )
                        })}
                    </nav>
                </div>
                <div className="flex items-center justify-end">
                    <PkbHeaderAction></PkbHeaderAction>
                </div>
                <div className="flex items-center justify-end">
                    <PkbHeaderUser></PkbHeaderUser>
                </div>
            </div>
        </>
    )
}

export default PkbHeader;