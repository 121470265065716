const PkbInstaIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.9628 12.1813C31.9399 9.42201 30.8334 6.7824 28.8821 4.83111L27.1996 3.14853C24.5931 0.541666 20.8121 -0.510186 17.2335 0.376079L8.76599 2.47339C4.55256 3.51658 1.41319 7.04095 0.861484 11.3466L0.0862384 17.3958C-0.328105 20.6266 0.77831 23.8672 3.08147 26.1704C4.72417 27.813 6.864 28.8657 9.16791 29.1641L17.0397 30.1836C20.2746 30.6028 23.5213 29.4968 25.8282 27.1898L28.919 24.0986C30.9132 22.1044 32.023 19.3933 31.9997 16.5735L31.9628 12.1813Z" fill="url(#paint0_radial_25460_19171)" />
            <path d="M3.92327 7.81003L8.55594 4.22997C11.853 1.68182 16.3765 1.42102 19.9449 3.57252L27.1163 7.89697C30.1469 9.72444 31.9996 13.0049 31.9996 16.544V18.8283C31.9996 21.4773 30.9587 24.0202 29.1015 25.9087L26.1446 28.9152C24.2464 30.8454 21.6527 31.9323 18.9454 31.9323H14.7489C11.617 31.9323 8.66244 30.4789 6.75067 27.9984L2.09957 21.9632C0.73837 20.1967 0 18.0294 0 15.7996C0.000376334 12.6726 1.44888 9.72181 3.92327 7.81003Z" fill="url(#paint1_radial_25460_19171)" />
            <path d="M18.8932 7.89551H13.0258C10.4242 7.89551 8.31519 10.0045 8.31519 12.6064V18.4731C8.31519 21.0743 10.4238 23.1829 13.0258 23.1829H18.8932C21.4948 23.1829 23.6034 21.0743 23.6034 18.4731V12.6064C23.6034 10.0045 21.4948 7.89551 18.8932 7.89551ZM22.136 17.9101C22.136 20.0127 20.4316 21.716 18.3302 21.716H13.5887C11.4865 21.716 9.7825 20.0127 9.7825 17.9101V13.1687C9.7825 11.0676 11.4865 9.36283 13.5887 9.36283H18.3302C20.4312 9.36283 22.136 11.0676 22.136 13.1687V17.9101Z" fill="white" />
            <path d="M16.1643 11.5396C13.9398 11.5396 12.1372 13.3418 12.1372 15.5659C12.1372 17.7893 13.9398 19.5927 16.1643 19.5927C18.3881 19.5927 20.1911 17.7893 20.1911 15.5659C20.1911 13.3418 18.3881 11.5396 16.1643 11.5396ZM16.1643 17.9884C14.8254 17.9884 13.7411 16.9034 13.7411 15.5659C13.7411 14.2269 14.8254 13.1431 16.1643 13.1431C17.5018 13.1431 18.5868 14.2269 18.5868 15.5659C18.5868 16.9034 17.5022 17.9884 16.1643 17.9884Z" fill="white" />
            <path d="M20.9362 11.4765C20.9362 12.0422 20.4771 12.5005 19.9118 12.5005C19.3462 12.5005 18.8882 12.0422 18.8882 11.4765C18.8882 10.9113 19.3462 10.4521 19.9118 10.4521C20.4771 10.4518 20.9362 10.9113 20.9362 11.4765Z" fill="white" />
            <defs>
                <radialGradient id="paint0_radial_25460_19171" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.8163 21.7568) scale(15.557)">
                    <stop offset="0.4051" stopColor="#FF51A3" />
                    <stop offset="0.6084" stopColor="#FF6CB6" />
                    <stop offset="1" stopColor="#FFAAE2" />
                </radialGradient>
                <radialGradient id="paint1_radial_25460_19171" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.76651 30.4131) scale(25.0439 25.0439)">
                    <stop stopColor="#A70F7A" />
                    <stop offset="0.2783" stopColor="#CA298A" />
                    <stop offset="0.5678" stopColor="#E73F98" />
                    <stop offset="0.8185" stopColor="#F94CA0" />
                    <stop offset="1" stopColor="#FF51A3" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export default PkbInstaIcon;