import { useQuery } from "@tanstack/react-query";
import { ajaxV1Get, ajaxV1Post, V1_TOURNAMENT, V1_TOURNAMENT_BOOK, V1_TOURNAMENT_DETAIL } from "../../share/ajax";

const getTournament = async (params) => {
    const response = await ajaxV1Get(V1_TOURNAMENT, {...params});

    return response;
}

const QUERY_TOURNAMENT_KEY = 'query-tournament-key'
export const UseTournamentList = (params) => {
    return useQuery({queryKey: [QUERY_TOURNAMENT_KEY, params], queryFn: () => getTournament(params), retry: 2})
}

const getTournamentDetail = async (params) => {
    const response = await ajaxV1Get(V1_TOURNAMENT_DETAIL, {...params});

    return response;
}

const QUERY_TOURNAMENT_DEETAIL_KEY = 'query-tournament-detail-key'
export const UseTournamentDetailList = (params) => {
    return useQuery({queryKey: [QUERY_TOURNAMENT_DEETAIL_KEY, params], queryFn: () => getTournamentDetail(params), retry: 2})
}

export const bookTournament = async (body) => {
    const response = await ajaxV1Post(V1_TOURNAMENT_BOOK, body);

    return response;
}