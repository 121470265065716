import { DateRangePicker, Input, InputGroup, SelectPicker } from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import { useEffect, useRef } from "react";
import CalenderSimpleIcon from '@rsuite/icons/CalenderSimple';
import PkbLocationIcon from "./icon/location-icon";

const styles = {
    marginBottom: 10,
    zIndex: 999,
    width: '100%'
};

const dataSelect = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const PkbFilter = () => {
    const containerRef = useRef(null)
    const selectPickerRef = useRef(null)

    useEffect(() => {
        if (!containerRef) { return }
        const clientWidth = containerRef.current.clientWidth;
        const columnWidth = (clientWidth - 64) / 3;
        containerRef.current.style.gridTemplateColumns = `${columnWidth}px ${columnWidth}px ${columnWidth}px`

        if (selectPickerRef) {
            selectPickerRef.current.target.style.border = 'none'
        }
    }, [])

    return (
        <>
            <div className="mt-12 pkb-filter-container z-10" ref={containerRef}>
                <div>
                    <label className="block text-base pkb-text-primary">Tên giải đấu</label>
                    <InputGroup inside style={styles}>
                        <InputGroup.Addon>
                            <SearchIcon width={24}  height={24}/>
                        </InputGroup.Addon>
                        <Input placeholder="Nhập tên giải đấu" />
                    </InputGroup>
                </div>
                <div>
                    <label className="block text-base pkb-text-primary">Thời gian tổ chức</label>
                    <InputGroup inside style={styles}>
                        <DateRangePicker caretAs={CalenderSimpleIcon} style={{ width: '100%', height: '44px' }} menuStyle={{ zIndex: '999' }} />
                    </InputGroup>
                </div>
                <div>
                    <label className="block text-base pkb-text-primary">Tên giải đấu</label>
                    <div>
                        {/* <SelectPicker searchable={false} data={dataSelect}
                            style={{
                                width: '100%'
                            }}
                            menuStyle={{
                                zIndex: '999',
                                border: 'none'
                            }} /> */}
                        <InputGroup inside style={styles}>
                            <InputGroup.Addon>
                                <PkbLocationIcon />
                            </InputGroup.Addon>
                            <SelectPicker ref={selectPickerRef} searchable={false} data={dataSelect}
                                placeholder="Chọn địa điểm"
                                style={{ width: '100%', border: 'none', height: '100%'}}
                                menuStyle={{
                                    zIndex: '999',
                                    border: 'none'
                                }} />
                        </InputGroup>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PkbFilter;