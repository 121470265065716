import { Card, CardContent, Chip } from "@mui/material";
import PkbBreadcumbs from "../../component/common/breadcrumbs";
import PkbHeader from "../../component/header/header";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PkbBtn from "../../component/common/button";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import PkbFooter from "../../component/footer/footer";
import TournamentLive from "../../component/tournament-live/tournament-live";
import { useEffect, useState } from "react";
import PkbLoginModal from "../../component/common/login-modal";
import PkbRegisterModal from "../../component/common/register.modal";
import { redirect, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UseTournamentDetailList } from "./service";
import { useDispatch } from "react-redux";
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

const TournamentDetail = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [tournamentId, setTournamentId] = useState('');
    const [showPwd, setShowPwd] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [days, setDays] = useState(14)
    const [hours, setHours] = useState(23)
    const [minutes, setMinutes] = useState(59)
    const [seconds, setSeconds] = useState(59)


    useEffect(() => {
        if (!location.pathname) { return }

        const pathName = location.pathname.split('/')
        const id = pathName[pathName.length - 1]

        setTournamentId(id)
    }, [location])

    useEffect(() => {
        if (seconds <= 0) {
            setSeconds(59)
        }

        const timerId = setInterval(() => {
            setSeconds((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId); // Clear the timer on cleanup
    }, [seconds]);

    useEffect(() => {
        if (minutes <= 0) {
            setMinutes(59)
        }

        const timerId = setInterval(() => {
            setMinutes((prevTime) => prevTime - 1);
        }, 60000);

        return () => clearInterval(timerId); // Clear the timer on cleanup
    }, [minutes]);

    useEffect(() => {
        if (days <= 0) {
            return
        }

        const timerId = setInterval(() => {
            setHours((prevTime) => prevTime - 1);
        }, 86400000);

        return () => clearInterval(timerId); // Clear the timer on cleanup
    }, [days]);


    useEffect(() => {
        if (hours <= 0) {
            setSeconds(59)
        }

        const timerId = setInterval(() => {
            setHours((prevTime) => prevTime - 1);
        }, 3600000);

        return () => clearInterval(timerId); // Clear the timer on cleanup
    }, [hours]);

    useEffect(() => {
        if (minutes <= 0) {
            setSeconds(59)
        }

        const timerId = setInterval(() => {
            setSeconds((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId); // Clear the timer on cleanup
    }, [minutes]);

    const { isPending, isError, data, error, refetch } = UseTournamentDetailList({ tournament_id: tournamentId });

    useEffect(() => {
        const Breadcrumbs = [
            {
                title: 'Trang chủ',
                link: '/home'
            },
            {
                title: 'Giải đấu',
                link: '/tournament'
            },
            {
                title: data?.results?.name,
                link: ''
            }
        ]

        dispatch({ type: 'set', breadcrumbs: Breadcrumbs })

        // const startDate = new Date(data?.results?.from_date)
        // const endDate = new Date(data?.results?.to_date)
    }, [data])

    return (
        <div>
            <div className="pkb-tournament-bg p-6">
                <PkbHeader></PkbHeader>
            </div>
            <div className="pkb-gray-bg pb-12">
                <div className="pt-16 px-[60px]">
                    <PkbBreadcumbs></PkbBreadcumbs>
                </div>
                <div className="grid grid-cols-2 gap-x-8 px-[60px] mt-12" style={{ gridTemplateColumns: '0.8fr auto' }}>
                    <div>
                        <Card className="z-10 bg-white " style={{ borderRadius: '16px' }}>
                            <CardContent style={{ padding: '24px 32px' }}>
                                {data?.results?.status === 3 ?
                                    <div>
                                        <Chip className="pkb-success-bg pbk-text-white mr-2" label="Giải đang mở đăng ký" />
                                        <Chip className="pbk-warning-bgcolor pbk-text-white mr-2" label="Giải đấu sắp diễn ra"
                                            avatar={<PlayArrowIcon className="text-white" color="inherit" style={{ 'color': '#fff' }}></PlayArrowIcon>} />
                                    </div> :
                                    <></>

                                }
                                {data?.results?.status === 2 ?
                                    <div>
                                        <Chip className="bg-[#919EAB29] pkb-text-primary mr-2" label="Giải đã đóng đăng ký" />
                                        <Chip className="pkb-danger-bg pbk-text-white mr-2" label="Giải đấu đang diễn ra"
                                            avatar={<div className="inline-block rounded-[50%] bg-white " style={{ width: '17px', height: '17px' }}></div>} />
                                    </div> :
                                    <></>
                                }

                                {data?.results?.status === 1 ?
                                    <div>
                                        <Chip className="bg-[#919EAB29] pkb-text-primary mr-2" label="Đóng đăng ký" />
                                        <Chip style={{ backgroundColor: '#919EAB', paddingLeft: '4px' }} className="pbk-text-white mr-2" label="Giải đấu đã kết thúc"
                                            avatar={<div className="inline-block bg-white " style={{ width: '17px', height: '17px' }}></div>} />
                                    </div> :
                                    <></>
                                }

                                <h2 className="text-[32px] font-bold">
                                    {data?.results?.name}
                                </h2>
                                <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                    <div>
                                        <FmdGoodOutlinedIcon className="text-base" fontSize="inherit"></FmdGoodOutlinedIcon>
                                    </div>
                                    <div>
                                        <p className="text-base text-left">{data?.results?.address}</p>
                                    </div>
                                    <div>
                                        <CalendarTodayOutlinedIcon className="text-base" fontSize="inherit"></CalendarTodayOutlinedIcon>
                                    </div>
                                    <div>
                                        <p className="text-base text-left">{data?.results?.from_date} - {data?.results?.to_date}</p>
                                    </div>
                                    <div>
                                        <LocalOfferOutlinedIcon className="text-base" fontSize="inherit"></LocalOfferOutlinedIcon>
                                    </div>
                                    <div>
                                        <p className="text-base text-left">Lệ phí giải: {data?.results?.phi_dang_ky} VND/Người</p>
                                    </div>
                                    <div>
                                        <GroupOutlinedIcon className="text-base" fontSize="inherit"></GroupOutlinedIcon>
                                    </div>
                                    <div>
                                        <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">{data?.results?.numbers_pplayer}/100</a></p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                        <Card className="z-10" style={{ borderRadius: '16px', height: '100%' }}>
                            {(data?.results?.status === 2 || data?.results?.status === 1) ?
                                <CardContent className="pkb-tournament-score-card-bg h-full">
                                    {data?.results?.status === 2 ?
                                        <div className="float">
                                            <div className="float-left text-white">
                                                <span>Live</span>
                                            </div>
                                            <div className="float-right">
                                                <div className="inline-block h-5 w-5 rounded-[50%] bg-[#54D62C] "></div>
                                            </div>
                                            <div className="clear-both"></div>
                                        </div>
                                        : <></>}

                                    <div className="flex items-center justify-center h-full text-white">

                                        <div className="text-center">
                                            <div className="max-w-[450px]">
                                                <h2 className="text-2xl">{data?.results?.name}</h2>

                                            </div>
                                            <div className="mt-12">
                                                {data?.results?.status === 2 ?
                                                    <PkbBtn btnTxt={'XEM LIVESCORE'} type="submit" className="w-fit px-4 py-2 text-white rounded-md hover:bg-blue-700"
                                                        startIcon={<div className="inline-block h-5 w-5 rounded-[50%] bg-white "></div>}
                                                        bgColor="#FF4842"
                                                        onClick={() => { }}></PkbBtn>
                                                    : <></>}
                                                {data?.results?.status === 1 ?
                                                    <PkbBtn btnTxt={'Xem kết quả trận đấu'} type="submit" className="w-fit px-4 py-2 text-white rounded-md hover:bg-blue-700"
                                                        startIcon={<LeaderboardOutlinedIcon className="inline-block h-5 w-5" />}
                                                        bgColor="transparent"
                                                        variant="outlined"
                                                        style={{
                                                            color: '#fff',
                                                            border: '1px solid #fff'
                                                        }}
                                                        onClick={() => { }}></PkbBtn>
                                                    : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                                : <CardContent className="pkb-tournament-register-card-bg h-full">
                                    <div className="flex items-center justify-center h-full">
                                        <div className="text-center">
                                            <h2 className="text-lg font-semibold text-gray-800 mb-4">Thời gian đăng ký còn lại</h2>

                                            <div className="flex justify-center space-x-4 mb-6">
                                                <div className="flex flex-col items-center px-4 py-2 border-2 rounded-md border-blue-400 bg-[#EBF2F8]">
                                                    <span id="days" className="text-2xl font-bold text-blue-600">{days}</span>
                                                    <span className="text-sm text-gray-700">Ngày</span>
                                                </div>
                                                <div className="flex flex-col items-center px-4 py-2 border-2 rounded-md border-blue-400 bg-[#EBF2F8]">
                                                    <span id="hours" className="text-2xl font-bold text-blue-600">{hours}</span>
                                                    <span className="text-sm text-gray-700">Giờ</span>
                                                </div>
                                                <div className="flex flex-col items-center px-4 py-2 border-2 rounded-md border-blue-400 bg-[#EBF2F8]">
                                                    <span id="minutes" className="text-2xl font-bold text-blue-600">{minutes}</span>
                                                    <span className="text-sm text-gray-700">Phút</span>
                                                </div>
                                                <div className="flex flex-col items-center px-4 py-2 border-2 rounded-md border-blue-400 bg-[#EBF2F8]">
                                                    <span id="seconds" className="text-2xl font-bold text-blue-600">{seconds}</span>
                                                    <span className="text-sm text-gray-700">Giây</span>
                                                </div>
                                            </div>

                                            <div className="mt-12">
                                                <PkbBtn btnTxt={'Đăng ký tham gia ngay'} type="submit" className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                                    startIcon={<DriveFileRenameOutlineOutlinedIcon />}
                                                    onClick={() => {
                                                        //handleOpen()
                                                        //setShowLoginModal(true)
                                                        setShowRegisterModal(true)
                                                    }}></PkbBtn>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>}
                        </Card>
                    </div>
                </div>
                <div className="px-[60px] mt-12">
                    <Card style={{ borderRadius: '8px' }}>
                        <CardContent >
                            <h1 className="text-2xl font-bold mb-4">Thông tin giải đấu</h1>

                            <h2 className="text-lg font-semibold mb-2">Thời gian và địa điểm tổ chức:</h2>
                            <p className="mb-4">
                                <strong>Thời gian tổ chức giải:</strong> 02 ngày, từ 26-27/10/2024<br />
                                <strong>Địa điểm thi đấu:</strong> Câu lạc bộ Lan Anh (Số 291 Cách Mạng Tháng Tám, Phường 12, Quận 10, TPHCM)
                            </p>
                            <h2 className="text-lg font-semibold mb-2">Đối tượng - Điều kiện tham dự:</h2>
                            <p className="mb-4">
                                Các VĐV là công dân Việt Nam, gồm VĐV, nhân viên văn phòng, người mới tập chơi Pickleball có giới tính là nữ, chuyển giới nữ và các bé trai, bé gái dưới 12 tuổi, các VĐV nam tham gia nội dung thi đấu vợ - chồng.
                            </p>
                            <p className="mb-4">
                                VĐV đăng ký tham gia tự đảm bảo sức khỏe của mình trong suốt quá trình tham gia giải; trung thực, tự giác tuân thủ quy định về trình độ, đăng ký đúng nội dung thi đấu.
                            </p>
                            <p className="mb-4">
                                Các VĐV dưới 18 tuổi phải có xác nhận đồng ý tham gia thi đấu bằng văn bản của cha mẹ, người giám hộ.
                            </p>
                            <h2 className="text-lg font-semibold mb-2">Nội dung thi đấu:</h2>
                            <ol className="list-decimal list-inside mb-4 space-y-2">
                                <li>Đôi nữ 4.5: Tổng điểm của 2 VĐV cao nhất là 4.5, điểm tối đa của 1 VĐV là 2.5 theo chuẩn USAPA.</li>
                                <li>Đôi nữ 6.0: Tổng điểm của cả 2 VĐV cao nhất là 6.0, điểm tối đa của 1 VĐV là 3.0 theo chuẩn USAPA.</li>
                                <li>Đôi nữ Open: Không giới hạn tổng điểm của 2 VĐV.</li>
                                <li>Đôi Mẹ - con: Đối tượng tham gia là các VĐV gồm mẹ và con (bé trai hoặc bé gái dưới 12 tuổi), có giấy khai sinh chứng minh quan hệ mẹ - con.</li>
                                <li>Đôi Vợ - chồng: Đối tượng tham gia là các VĐV nam - nữ là vợ chồng, có giấy đăng ký kết hôn.</li>
                            </ol>
                        </CardContent>
                    </Card>
                </div>
                <div className="px-[60px] mt-12">
                    <Card style={{ borderRadius: '8px' }}>
                        <CardContent>
                            <h1 className="text-2xl font-bold text-gray-800 mb-6">Quy định kỹ năng - trình điểm</h1>
                            <div className="mb-6">
                                <h2 className="text-xl font-semibold text-gray-700 mb-4">Kỹ năng cấp độ/trình 2.0</h2>
                                <ul className="list-disc list-inside space-y-2 text-gray-600">
                                    <li>Hiểu biết tối thiểu về các quy tắc cơ bản của pickleball như quy tắc bóng nảy 2 lần.</li>
                                    <li>Biết cách ghi điểm.</li>
                                    <li>Có thể thực hiện cú đánh thuận tay.</li>
                                    <li>Có thể thực hiện cú đánh trái tay.</li>
                                    <li>Có thể thực hiện cú đánh volley.</li>
                                    <li>Phát bóng chính xác vào phần sân thích hợp.</li>
                                    <li>Biết vị trí đứng phù hợp khi đánh bóng và trả phát bóng.</li>
                                    <li>Có khả năng di chuyển tốt (an toàn và cân bằng).</li>
                                    <li>Nhanh nhẹn.</li>
                                    <li>Phản ứng mặt - tay tốt.</li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold text-gray-700 mb-4">Kỹ năng cấp độ/trình 2.5</h2>
                                <p className="text-gray-600">Bao gồm cả các kỹ năng của trình 2.0.</p>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                {/* <div>
                    <TournamentLive
                        label="Các giải đấu khác"
                        tailColor="#0162AC"
                        classes="grid-cols-3"
                        footerColor="#FCC134"
                        footerBtnTxt="Đăng ký"
                        footerIcon={<PlayArrowIcon className="text-white" color="inherit"></PlayArrowIcon>}
                        dataLength={3}
                        isDetail={true}></TournamentLive>
                </div> */}
            </div>
            <PkbFooter></PkbFooter>
            {/* register modal */}
            <PkbRegisterModal open={showRegisterModal}
                data={data?.results}
                onClose={() => setShowRegisterModal(false)}
                tournament_id={tournamentId}
                tournament_name={data?.results?.name}
                onSuccess={(res) => {
                    setShowRegisterModal(false)
                    navigate('/tournament/detail/payment', { replace: true })
                }}
                onSubmit={() => setShowRegisterModal(false)}></PkbRegisterModal>
            {/* login modal */}
            <PkbLoginModal open={showLoginModal} onClose={() => setShowLoginModal(false)}
                showPwd={showPwd}
                onChangeShowPwd={() => setShowPwd(!showPwd)}></PkbLoginModal>
        </div>
    )
}

export default TournamentDetail;