const PkbPaperIcon = (props) => {
    return (
        <svg className="inline" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_25468_2496)">
                <path opacity="0.2" d="M40.5 9H7.5C7.10218 9 6.72064 9.15804 6.43934 9.43934C6.15804 9.72064 6 10.1022 6 10.5V40.5L12 37.5L18 40.5L24 37.5L30 40.5L36 37.5L42 40.5V10.5C42 10.1022 41.842 9.72064 41.5607 9.43934C41.2794 9.15804 40.8978 9 40.5 9ZM21 30H12V18H21V30Z" fill={'#0162AC'} />
                <path d="M6 40.5V10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72064 9.15804 7.10218 9 7.5 9H40.5C40.8978 9 41.2794 9.15804 41.5607 9.43934C41.842 9.72064 42 10.1022 42 10.5V40.5L36 37.5L30 40.5L24 37.5L18 40.5L12 37.5L6 40.5Z" stroke={props?.color || '#0162AC'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 21H36" stroke={props?.color || '#0162AC'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 27H36" stroke={props?.color || '#0162AC'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 18H12V30H21V18Z" stroke={props?.color || '#0162AC'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_25468_2496">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PkbPaperIcon;