const PkbFbIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2174 1.54659L25.4614 4.2933C29.4868 6.40163 31.9735 10.3432 31.9735 14.6138V17.2987C31.9735 20.1465 30.8639 22.8993 28.8469 25.0547L26.1956 27.8882C23.7526 30.4987 20.1997 31.9996 16.4618 31.9996C11.2694 31.9996 6.58683 29.1186 4.59018 24.6959L1.0187 16.7844C-1.7548 10.6405 1.48806 3.5938 8.20505 1.1693L9.18413 0.815772C12.7933 -0.487407 16.847 -0.218773 20.2174 1.54659Z" fill="url(#paint0_radial_25460_19126)" />
            <path d="M1.54659 11.782L4.2933 6.538C6.40163 2.51263 10.3429 0.0258789 14.6138 0.0258789H17.2991C20.1469 0.0258789 22.8996 1.13551 25.0551 3.15253L27.8886 5.80378C30.4991 8.24677 32 11.7998 32 15.5376C32 20.73 29.119 25.4126 24.6963 27.4092L16.7848 30.9807C10.6409 33.7542 3.59418 30.5114 1.1693 23.7944L0.815772 22.8153C-0.487407 19.2065 -0.218773 15.1528 1.54659 11.782Z" fill="url(#paint1_radial_25460_19126)" />
            <path d="M18.4203 10.5537H20.0679V7.96399H18.0218H17.5989C17.5989 7.96399 16.0467 7.92551 15.0567 9.21171C15.0567 9.21171 14.3828 9.84707 14.3723 11.7083L14.3719 13.6506H11.9312V16.4007H14.3719V23.5048H17.1869V16.4003H19.6084L19.946 13.6502H17.1869V11.7079H17.1861C17.1929 11.4913 17.2895 10.5341 18.4203 10.5537Z" fill="white" />
            <defs>
                <radialGradient id="paint0_radial_25460_19126" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.81737 25.4045) rotate(90) scale(38.9087 42.1654)">
                    <stop stopColor="#4F70B6" />
                    <stop offset="0.3078" stopColor="#3F9CC3" />
                    <stop offset="0.627" stopColor="#31C4CF" />
                    <stop offset="0.793" stopColor="#2CD3D4" />
                </radialGradient>
                <radialGradient id="paint1_radial_25460_19126" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-3.51305 32.817) scale(38.9089 42.1655)">
                    <stop offset="0.0105" stopColor="#263F95" />
                    <stop offset="0.4489" stopColor="#3A57A5" />
                    <stop offset="0.9856" stopColor="#4F70B6" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export default PkbFbIcon;