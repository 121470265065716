const PkbYtIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.259 30.6663L21.361 29.5602C27.5211 28.4434 32 23.0795 32 16.819V13.3442C32 6.19282 26.2027 0.395508 19.0513 0.395508H15.1806C10.2278 0.395508 5.7088 3.22075 3.53963 7.67315L1.30792 12.254C0.0903143 14.7532 -0.290531 17.5777 0.221678 20.3102C1.53385 27.3101 8.25181 31.9365 15.259 30.6663Z" fill="url(#paint0_radial_25460_19153)" />
            <path d="M2.26383 15.5615L3.32394 21.4092C4.39399 27.3125 9.53411 31.6044 15.5338 31.6044H18.8635C25.7165 31.6044 31.2721 26.0489 31.2721 19.1959V15.4868C31.2721 10.7404 28.5646 6.41013 24.298 4.33149L19.9082 2.19286C17.5131 1.02604 14.8063 0.66102 12.1879 1.15189C5.48023 2.40886 1.0466 8.84644 2.26383 15.5615Z" fill="url(#paint1_radial_25460_19153)" />
            <path d="M11.1128 10.1694L22.2077 15.9605L11.1128 21.7497V10.1694Z" fill="white" />
            <defs>
                <radialGradient id="paint0_radial_25460_19153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.8439 31.5922) rotate(-90) scale(26.3507 26.2327)">
                    <stop offset="0.0837" stopColor="#DE2925" />
                    <stop offset="0.1942" stopColor="#E13734" />
                    <stop offset="0.4055" stopColor="#EA5D5A" />
                    <stop offset="0.6936" stopColor="#F99998" />
                    <stop offset="0.8153" stopColor="#FFB4B4" />
                </radialGradient>
                <radialGradient id="paint1_radial_25460_19153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.66059 33.2396) scale(37.4497 37.282)">
                    <stop stopColor="#59184F" />
                    <stop offset="0.2376" stopColor="#881E40" />
                    <stop offset="0.463" stopColor="#AE2334" />
                    <stop offset="0.6714" stopColor="#C8262C" />
                    <stop offset="0.8567" stopColor="#D82827" />
                    <stop offset="1" stopColor="#DE2925" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export default PkbYtIcon;