import { DatePicker, Input, InputGroup, Modal, Radio, RadioGroup } from "rsuite";
import PkbBtn from "./button";
import * as Yup from 'yup';
import { phoneRegExp } from "../../share/common";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { bookTournament } from "../../pages/tournament/service";
import { useDispatch } from "react-redux";

const styles = {
    width: '100%',
    marginBottom: 10
};

const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().required('Required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
        .required('Required'),
    birthday: Yup.string().required('Required'),
    player_rating: Yup.string(),

});

const PkbRegisterModal = (props) => {
    const [typePlayer, setTypePlayer] = useState("");
    const [cate, setCate] = useState([]);
    const [cateId, setCateId] = useState("");
    const [couple, setCouple] = useState(0);
    const dispatch = useDispatch()

    const onSubmit = async () => {
        values['tournament_id'] = props?.tournament_id
        values['tournament_name'] = props?.tournament_name

        try {
            const response = await bookTournament(values)
            dispatch({ type: 'set', tourRegister: {...values, couple} })
            dispatch({ type: 'set', tourPayment: {...response?.results } })
            props?.onSuccess(response)
        } catch {
            console.log('catch error')
        }
        
    }
    const { values, errors, setFieldValue, touched, handleBlur, handleSubmit, submitForm } = useFormik({
        initialValues: {
            fullname: '',
            phone: '',
            birthday: '',
            player_rating: '',
            type_player_id: '',
            tournament_cate_id: '',
            player2_fullname: '',
            player2_phone: '',
            player2_birthday: '',
            tournament_cate_name: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            onSubmit()
        },
    });

    useEffect(() => {
        if (!props?.data?.type_player?.length) { return }

        setTypePlayer(props?.data?.type_player[0]?.id || "")
        setFieldValue('type_player_id', props?.data?.type_player[0]?.id || "")
    }, [props?.data])
    useEffect(() => {
        if (!props?.data?.type_player?.length) { return }

        const selectType = props?.data?.type_player.find(x => x.id === typePlayer)

        const newCate = selectType?.cate || []

        setCate(() => [...newCate])
        setCateId(newCate[0]?.id || "")
        setFieldValue('tournament_cate_id', newCate[0]?.id || "")
    }, [typePlayer])

    useEffect(() => {
        if (!props?.data?.type_player?.length) { return }

        const cates = props?.data?.type_player.find(x => x.id === typePlayer)?.cate || []

        if (!cates?.length) { return }
        const selectCate = cates.find(x => x.id === cateId)

        if (!selectCate) {
            setCouple(0)
            return;
        }

        setCouple(parseInt(selectCate.couple))
        setFieldValue('tournament_cate_name', selectCate.name)
    }, [cateId])
    return (
        <Modal
            open={props?.open}
            overflow={true}
            onClose={() => props?.onClose()}
            // style={{ width: '500px'}}
            size={500}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Modal.Header>
                <h2 className="text-[20px] font-bold text-center pkb-text-primary">Đăng ký tham gia giải đấu</h2>
            </Modal.Header>
            <Modal.Body>
                <form className="mt-3" onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-base pkb-text-primary">Họ và tên <span className="text-[#FF4842]">*</span></label>
                        <input type="text" placeholder="Nhập họ và tên" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input"
                            onChange={(event) => {
                                setFieldValue('fullname', event.target.value)
                            }} />
                    </div>
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Số điện thoại <span className="text-[#FF4842]">*</span></label>
                        <input type="text" placeholder="Nhập email hoặc số điện thoại" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input"
                            onChange={(event) => {
                                setFieldValue('phone', event.target.value)
                            }} />
                    </div>
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Ngày sinh <span className="text-[#FF4842]">*</span></label>
                        <DatePicker style={{ width: '100%' }} onChange={(event) => {
                            let date = new Date(event);
                            let dateStr = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                            setFieldValue('birthday', dateStr)
                        }} />
                    </div>
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Điểm trình</label>
                        <input type="text" placeholder="Nhập điểm trình" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input"
                            onChange={(event) => {
                                setFieldValue('player_rating', event.target.value)
                            }} />
                    </div>
                    {/* <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Bạn đã tải app VPickleball chưa?</label>
                        <RadioGroup name="radio-group-inline" inline defaultValue="A">
                            <Radio value="A">Đã tải</Radio>
                            <Radio value="B">Chưa</Radio>
                        </RadioGroup>
                    </div>
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Mã VPA <span className="text-[#FF4842]">*</span></label>
                        <InputGroup style={styles}>
                            <InputGroup.Addon> VPA</InputGroup.Addon>
                            <Input />
                        </InputGroup>
                    </div> */}
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Nội dung thi đấu</label>
                        <RadioGroup name="radio-group-inline" value={typePlayer} inline onChange={(value) => {
                            setTypePlayer(value + '')
                            setFieldValue('type_player_id', value)
                        }}>
                            {props?.data?.type_player?.length ?
                                props?.data?.type_player.map((item, idx) => {
                                    return (
                                        <Radio key={idx} value={item.id}>{item.name}</Radio>
                                    )
                                })
                                : <></>}
                            {/* <Radio value="A">Phong trào</Radio>
                            <Radio value="B">Chuyên nghiệp</Radio> */}
                        </RadioGroup>
                    </div>
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Nhóm đấu</label>
                        <RadioGroup name="radio-group-controlled" value={cateId} onChange={(value) => {
                            setCateId(value + '')
                            setFieldValue('tournament_cate_id', value)
                        }}>
                            {cate?.length ?
                                cate.map((item, idx) => {
                                    return (
                                        <Radio key={idx} value={item.id}>{item.name}</Radio>
                                    )
                                })
                                : <></>}
                            {/* <Radio value="A">Đơn Nam</Radio>
                            <Radio value="B">Đơn Nữ</Radio>
                            <Radio value="C">Đôi Nam</Radio>
                            <Radio value="D">Đôi Nữ</Radio>
                            <Radio value="D">Đôi Nam Nữ</Radio> */}
                        </RadioGroup>
                    </div>
                    {couple > 0 ?
                        <div className="mt-4 ">
                            <h1 className="font-medium text-base">Thông tin người chơi cùng</h1>
                            <div className="rounded-xl bg-[#F9FAFB] p-3">
                                <div>
                                    <label className="block text-base pkb-text-primary">Họ và tên <span className="text-[#FF4842]">*</span></label>
                                    <input type="text" placeholder="Nhập họ và tên" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input"
                                        onChange={(event) => {
                                            setFieldValue('player2_fullname', event.target.value)
                                        }} />
                                </div>
                                <div className="mt-4">
                                    <label className="block text-base pkb-text-primary">Số điện thoại<span className="text-[#FF4842]">*</span></label>
                                    <input type="text" placeholder="Nhập email hoặc số điện thoại" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input"
                                        onChange={(event) => {
                                            setFieldValue('player2_phone', event.target.value)
                                        }} />
                                </div>
                                <div className="mt-4">
                                    <label className="block text-base pkb-text-primary">Ngày sinh <span className="text-[#FF4842]">*</span></label>
                                    <DatePicker style={{ width: '100%' }}
                                        onChange={(event) => {
                                            let date = new Date(event);
                                            let dateStr = `${date.getDate()}/${date.getDay()}/${date.getFullYear()}`
                                            setFieldValue('player2_birthday', dateStr)
                                        }} />
                                </div>
                            </div>
                        </div>
                        : <></>}

                    {/* <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Nội dung <span className="text-[#FF4842]">*</span></label>
                        <SelectPicker
                            data={mockData}
                            searchable={false}
                            style={{ width: '100%' }}
                            placeholder="Chọn nội dung thi đấu"
                        />
                    </div>
                    <div className="mt-4">
                        <label className="block text-base pkb-text-primary">Nhóm đấu <span className="text-[#FF4842]">*</span></label>
                        <SelectPicker
                            data={mockData}
                            searchable={false}
                            style={{ width: '100%' }}
                            placeholder="Chọn Nhóm đấu"
                        />
                    </div> */}

                </form>
            </Modal.Body>
            <Modal.Footer>
                <PkbBtn bgColor="#fff" btnTxt={'Đóng'}
                    className="px-4 py-2 text-white bg-white rounded-md mr-6"
                    style={{
                        color: '#000',
                        border: '1px solid #919EAB3D',
                        marginRight: '24px',
                        boxShadow: 'none'
                    }}
                    onClick={() => props?.onClose()}></PkbBtn>
                <PkbBtn btnTxt={'Đăng ký'} className="px-4 py-2 text-white rounded-md hover:bg-blue-700"
                    onClick={() => {
                        onSubmit()
                        //props?.onSubmit()
                    }}></PkbBtn>
            </Modal.Footer>
        </Modal>
    )
}

export default PkbRegisterModal;