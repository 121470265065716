import { Button } from "@mui/material";
import PkbVertitalLogo from "../common/logo-vertical";
import PkbLogo from "../header/logo";

const PkbContact = () => {
    return (
        <>
            <div className="py-16 px-[60px] bg-white pkb-contact-bg">
                <div className="flex">
                    <div className="float-left w-3/5 h-full">
                        <div className="flex px-8 py-12 bg-white rounded-3xl">
                            <div className="float-left relative px-[35.74px]">
                                <div className="flex justify-center">
                                    <PkbVertitalLogo></PkbVertitalLogo>
                                </div>
                                <h1 className="pb-3 pt-6 font-medium text-base">Kết nối cộng đồng Pickleball mọi lúc, mọi nơi!</h1>
                                <p className="text-sm">Tải ngay ứng dụng VPickleball để cập nhật tin tức và các giải đấu hấp dẫn.
                                    Ứng dụng đã có trên Google Play và Apple Store sẵn sàng cho bạn khám phá.
                                </p>
                                <div className="absolute bottom-0 left-1/2 translate-x-[-50%]">
                                    <div className="flex justify-center">
                                        <a href="">
                                            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/appstore.png`} alt="" width={200} height={59.67} />
                                        </a>
                                    </div>
                                    <div className="mt-6 flex justify-center">
                                        <a href="">
                                            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/ios-appstore.png`} alt="" width={200} height={59.67} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="float-right">
                                <img className="rounded-[42px]" src={`${process.env.PUBLIC_URL}/phone-screen.png`} alt="" />
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="float-right w-2/5 pl-12">
                        <div className="text-white font-bold">
                            <h2 className="text-[32px]">
                                LIÊN HỆ VỚI VPICKLEBALL
                            </h2>
                            <div className="contact-form text-left">
                                <form className="text-base font-normal">
                                    <div>
                                        <p className="mb-2 mt-6">Tên doanh nghiệp/Tổ chức/Cá nhân</p>
                                        <input
                                            className="rounded-lg px-3.5 py-4 w-full"
                                            type="text" id="name" placeholder="Nhập Tên doanh nghiệp/Tổ chức/Cá nhân" />
                                    </div>
                                    <div>
                                        <p className="mb-2 mt-6">Số điện thoại</p>
                                        <input className="rounded-lg px-3.5 py-4 w-full" type="text" id="phone" placeholder="Nhập số điện thoại" />
                                    </div>
                                    <div>
                                        <p className="mb-2 mt-6">Email</p>
                                        <input className="rounded-lg px-3.5 py-4 w-full" type="email" id="email" placeholder="Nhập email" />
                                    </div>
                                    <div className="mb-6">
                                        <p className="mb-2 mt-6">Nội dung</p>
                                        <textarea className="rounded-lg px-3.5 py-4 h-32 w-full" id="message" placeholder="Nhập nội dung"></textarea>
                                    </div>
                                    <Button className="w-full" variant="contained" type="submit">Gửi</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>
        </>
    )
}

export default PkbContact;