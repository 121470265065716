import PkbSlideShow from "../slideshow/slide-show";

const PkbPartner = () => {
    return (
        <>
            <div className="pkb-partner-bg p-[60px] py-16">
                <h2 className="text-5xl font-bold  text-center p-0 mb-12">Câu lạc bộ đồng hành cùng
                    <span className="pkb-primary-color"> Vpickleball</span>
                </h2>
                <PkbSlideShow></PkbSlideShow>
            </div>
        </>
    )
}

export default PkbPartner;