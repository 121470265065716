import { useNavigate } from "react-router-dom";
import { Button } from "../../../node_modules/@mui/material/index";

const PkbHeaderUser = () => {
    const navigate = useNavigate();
    return(
        <>
            <div className="text-sm font-bold">
                <Button variant="text"
                style={{
                    'color': '#000',
                    'textTransform': 'none'
                }}
                onClick={() => {
                    navigate('/login')
                }}>Đăng nhập</Button>
                <Button variant="contained"
                style={{
                    'color': '#fff',
                    'textTransform': 'none'
                }}
                onClick={() => {
                    navigate('/register', {replace: true})
                }}>Đăng ký</Button>
            </div>
        </>
    )    
}

export default PkbHeaderUser;