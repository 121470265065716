import { Button } from "@mui/material"

const PkbBtn = (props) => {
    return(
        <>
            <Button variant={props?.variant || 'contained'}
                startIcon={props?.startIcon}
                className={props?.className}
                style={{
                    'textTransform': 'none',
                    'backgroundColor': `${props?.bgColor}`|| 'linear-gradient(121.9deg, #0061AB 21.1%, #005187 82.74%)',
                    ...props?.style
                }}
                onClick={() => props?.onClick()}
            >
                {props?.btnTxt}
            </Button>
        </>
    )
}

export default PkbBtn