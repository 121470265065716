import { useQuery } from "@tanstack/react-query";
import { ajaxGet, GET_ARTICLES } from "../../share/ajax";

const getArticles = async (params) => {
    const response = await ajaxGet(GET_ARTICLES, params);

    return response;
}

const QUERY_ARTICLE_KEY = 'query-articles-key'
export const UseGetArticles = (params) => {
    return useQuery({queryKey: [QUERY_ARTICLE_KEY, params], queryFn: () => getArticles(params), retry: 2})
}

const getArticlesDetail = async (id) => {
    const response = await ajaxGet(GET_ARTICLES + '/' + id);

    return response;
}

const QUERY_ARTICLE_DETAIL_KEY = 'query-article-detail-key'
export const UseGetArticleDetail = (id) => {
    return useQuery({queryKey: [QUERY_ARTICLE_DETAIL_KEY, id], queryFn: () => getArticlesDetail(id), retry: 2})
}