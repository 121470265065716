const EyeOpenIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25749_4839)">
                <path opacity="0.2" d="M22.4485 12C22.4427 12.0126 22.4339 12.0312 22.4222 12.0555C22.3987 12.1045 22.363 12.1765 22.3149 12.2683C22.2188 12.4519 22.0729 12.7145 21.8744 13.0297C21.4774 13.6603 20.8701 14.5011 20.0294 15.3419C18.3481 17.0231 15.7344 18.7031 12 18.7031C8.26559 18.7031 5.65187 17.0231 3.97065 15.3419C3.1299 14.5011 2.52263 13.6603 2.1256 13.0297C1.92712 12.7145 1.78125 12.4519 1.68508 12.2683C1.637 12.1765 1.60134 12.1045 1.57776 12.0555C1.56606 12.0312 1.55733 12.0126 1.55153 12C1.55733 11.9874 1.56606 11.9688 1.57776 11.9445C1.60134 11.8955 1.637 11.8235 1.68508 11.7317C1.78125 11.5481 1.92712 11.2855 2.1256 10.9703C2.52263 10.3397 3.1299 9.49889 3.97065 8.65815C5.65187 6.97692 8.26559 5.29688 12 5.29688C15.7344 5.29688 18.3481 6.97692 20.0294 8.65815C20.8701 9.49889 21.4774 10.3397 21.8744 10.9703C22.0729 11.2855 22.2188 11.5481 22.3149 11.7317C22.363 11.8235 22.3987 11.8955 22.4222 11.9445C22.4339 11.9688 22.4427 11.9874 22.4485 12ZM9.89057 15.157C10.515 15.5742 11.249 15.7969 12 15.7969C13.007 15.7969 13.9727 15.3968 14.6848 14.6848C15.3968 13.9727 15.7969 13.007 15.7969 12C15.7969 11.249 15.5742 10.515 15.157 9.89057C14.7398 9.26618 14.1468 8.77952 13.453 8.49214C12.7592 8.20477 11.9958 8.12958 11.2593 8.27608C10.5227 8.42258 9.84621 8.7842 9.3152 9.3152C8.7842 9.84621 8.42258 10.5227 8.27608 11.2593C8.12958 11.9958 8.20477 12.7592 8.49214 13.453C8.77952 14.1468 9.26618 14.7398 9.89057 15.157Z" fill="#637381" stroke="#637381" stroke-width="0.09375" />
                <path d="M12 5.25C4.5 5.25 1.5 12 1.5 12C1.5 12 4.5 18.75 12 18.75C19.5 18.75 22.5 12 22.5 12C22.5 12 19.5 5.25 12 5.25Z" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke="#637381" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_25749_4839">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EyeOpenIcon;