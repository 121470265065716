const PkbBorderSplit = (props) => {
    return (
        <>
            {props?.border2 ? <>
                <svg className={props?.classes} width="312" height="384" viewBox="0 0 312 384" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="2" height="100" transform="matrix(-1 0 0 1 312 180.5)" fill="#0162AC" />
                    <rect width="2" height="100" transform="matrix(-1 0 0 1 2 70.5)" fill="#0162AC" />
                    <rect width="2" height="50" transform="matrix(-1 0 0 1 312 0)" fill="#0162AC" />
                    <rect width="2" height="50" transform="matrix(-1 0 0 1 2 334)" fill="#0162AC" />
                    <rect width="50" height="2" transform="matrix(-1 0 0 1 312 0)" fill="#0162AC" />
                    <rect width="50" height="2" transform="matrix(-1 0 0 1 50 382)" fill="#0162AC" />
                </svg>

            </> : <>
                <svg className={props?.classes} width="312" height="384" viewBox="0 0 312 384" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="180.5" width="2" height="100" fill="#0162AC" />
                    <rect x="310" y="70.5" width="2" height="100" fill="#0162AC" />
                    <rect width="2" height="50" fill="#0162AC" />
                    <rect x="310" y="334" width="2" height="50" fill="#0162AC" />
                    <rect width="50" height="2" fill="#0162AC" />
                    <rect x="262" y="382" width="50" height="2" fill="#0162AC" />
                </svg>
            </>}
        </>


    )
}

export default PkbBorderSplit;