import { Box, Button } from "@mui/material";
import { useCallback } from "react";
import { useSlideshow } from "slideshow-react";
import PkbBorderSplit from "../common/border-split";

const getBlurSrc = (imageMetadata) => {
    return imageMetadata.src + ",bl-12";
};

const slideOptions = {
    onSlideScrollStart: (idx) => {
    },
    onSlideScrollEnd: (idx) => {
    },
};


const PkbSlideShow = () => {

    const getSizes = () => `296px, 296px`;

    const {
        rootSlidesContainerRef,
        slides,
        setSlideIdx,
    } = useSlideshow(DefaultSlides, {
        getSizes,
        getThumbnailBlurSrc: getBlurSrc,
        startingIndex: 1,
        ...slideOptions,
    });

    return (
        <Box className="default-container">
            {/* <Button onClick={goPreviousSlide}>Prev</Button>
            <Button onClick={goNextSlide}>Next</Button> */}
            <Box
                ref={rootSlidesContainerRef}
                sx={{
                    position: "relative",
                    width: "100%",
                    height: { md: "396px", },
                    display: "flex",
                    gap: "20px",
                    overflowX: "hidden",
                    overflowY: "hidden",
                    scrollSnapType: "x mandatory",
                }}
            >
                {slides.map((slide, idx) => {
                    return (
                        <Box
                            key={idx}
                            ref={slide.ref}
                            data-idx={slide.dataIdx}
                            sx={{
                                scrollSnapAlign: "center",
                                scrollSnapStop: "always",
                                position: "relative",
                                maxWidth: { xs: "296px", md: "312px", lg: "312px" },
                                flexBasis: "100%",
                                flexGrow: 0,
                                flexShrink: 0,
                                height: "100%",
                                display: "block",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {"image" in slide ? (
                                <>
                                    {slide.blurImage && (
                                        <Box
                                            component="img"
                                            sx={{
                                                width: "296px",
                                                height: "296px",
                                                objectFit: "cover",
                                                position: "absolute",
                                                top: '8px',
                                                left: '8px',
                                                opacity: slide.loaded ? 0 : 1,
                                                transition: "opacity 0.2s linear",
                                            }}
                                            {...slide.blurImage}
                                        />
                                    )}
                                    {slide.isSetToLoad && (
                                        <Box
                                            component="img"
                                            className="block"
                                            sx={{
                                                width: "296px",
                                                height: "296px",
                                                objectFit: "cover",
                                                marginTop: '8px',
                                                marginLeft: '8px'
                                            }}
                                            {...slide.image}
                                        />
                                    )}
                                </>
                            ) : (
                                slide.component
                            )}
                            <h1 className="text-lg font-medium px-3 pt-4">{slide['image']?.title}</h1>
                            {(idx % 2) === 0
                                ? <PkbBorderSplit border2={false} classes={'absolute top-0'}></PkbBorderSplit>
                                : <PkbBorderSplit border2={true} classes={'absolute top-0'}></PkbBorderSplit>}

                        </Box>
                    );
                })}
            </Box>
            <div className="pkb-progress-container">
                <div className="pkb-steps">
                    {slides.map((slide, idx) => {
                        return (
                            <div className={slide.active ? 'pkb-progress-bar' : 'pkb-step'}
                                onClick={() => setSlideIdx(idx)}></div>
                        )
                    })}
                </div>
            </div>
        </Box>
    )
}

export default PkbSlideShow;

export const DefaultSlides = [
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner1.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL TRẺ HỒ CHÍ MINH',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner2.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL 01',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner3.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL 02',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner4.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL TRẺ HÀ NỘI',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner1.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL TRẺ HỒ CHÍ MINH',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner2.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL 01',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner3.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL 02',
        },
    },
    {
        image: {
            src: `${process.env.PUBLIC_URL}/partner4.png`,
            title: 'CÂU LẠC BỘ PICKLEBALL TRẺ HÀ NỘI',
        },
    },
];