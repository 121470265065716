import './App.css';
import Home from './pages/home/home';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <div className="App bg-slate-300">
        <Home></Home>
    </div>
  );
}

export default App;
