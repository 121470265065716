import { useQuery } from "@tanstack/react-query";
import { ajaxGet, GET_IMAGES } from "../../share/ajax";

const getBannerImg = async (params) => {
    const response = await ajaxGet(GET_IMAGES, {...params});

    return response;
}

const QUERY_MAIN_BANNER_IMG_KEY = 'query-main-banner-img-key'
export const UseMainBannerImg = (params) => {
    return useQuery({queryKey: [QUERY_MAIN_BANNER_IMG_KEY, params], queryFn: () => getBannerImg(params), retry: 2})
}