
const PkbTailFlag = (props) => {
    return (
        <>
            <div className="float z-10">
                <div style={{ 'backgroundColor': props?.color || '#FF4842', 'borderRadius': '8px 0 0 8px' }}
                    className={'float-left h-[52px] pl-6 w-[325px] ' + props.classes}>
                    
                    {/* {props?.icon === 'circle' ?
                        <div className="inline-block h-9 w-9 rounded-[50%] bg-white translate-y-[8px]"></div> :
                        <></>}
                    {props?.icon === 'square' ?
                        <div className="inline-block h-9 w-9 bg-white translate-y-[8px]"></div> :
                        <></>}
                    {props?.customIcon ?
                        <div className="inline-block h-9 w-9 translate-y-[8px]">
                            {props?.customIcon}
                        </div>

                        :
                        <></>}
                    <div className={
                        'h-full items-center text-2xl text-white ml-2 font-bold '
                        + ((props?.icon || props?.customIcon) ? 'inline-block' : 'flex')
                        +(props?.customIcon ? ' align-middle' : '')}>
                        <span className={(props?.customIcon ? ' align-sub' : '')}>{props?.label}</span>
                    </div> */}
                    <div className="flex items-center h-full">
                        {props?.icon === 'circle' ?
                        <div className="inline-block h-9 w-9 rounded-[50%] bg-white "></div> :
                        <></>}
                    {props?.icon === 'square' ?
                        <div className="inline-block h-9 w-9 bg-white "></div> :
                        <></>}
                    {props?.customIcon ?
                        <div className="inline-block h-9 w-9">
                            {props?.customIcon}
                        </div>

                        :
                        <></>}
                    <div className={
                        'h-fit text-2xl text-white ml-2 font-bold ' }>
                        <span>{props?.label}</span>
                    </div>
                    </div>
                </div>
                <div className="float-left h-[52px] ">
                    <svg width="40" height="52" viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40 52H0V26V0H40L0 26L40 52Z" fill={props?.color || '#FF4842'} />
                    </svg>

                </div>
                <div className="clear-both"></div>
            </div>
        </>
    )
}

export default PkbTailFlag;