const PkbTiktokIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.7168 4.35251L27.9208 3.50638C25.7001 1.14457 22.6479 -0.122162 19.506 0.0132626L12.9442 0.296182C10.1229 0.417649 7.44799 1.66062 5.45171 3.77875L3.2985 6.06247C0.913297 8.59253 -0.274215 12.1241 0.0622704 15.684L0.1298 16.4C0.48892 20.2013 2.54367 23.5899 5.65578 25.513L13.5225 30.3751C17.8741 33.0651 23.3813 32.3559 27.0004 28.6398V28.6395C28.5629 27.0351 29.6534 24.9845 30.1393 22.7359L31.7161 15.4369C32.5788 11.4413 31.4464 7.25488 28.7168 4.35251Z" fill="url(#paint0_radial_25460_19184)" />
            <path d="M26.4477 5.29428L30.2132 11.2986C31.3732 13.1485 31.9718 15.2949 31.9364 17.4779C31.8541 22.5339 28.4191 26.918 23.5295 28.2074L14.6911 30.5379C11.1946 31.4598 7.46832 30.6507 4.66892 28.3617L4.14156 27.9305C1.52021 25.7871 0 22.5799 0 19.1939V16.1365C0 13.3017 1.0668 10.5709 2.98839 8.48675L7.45776 3.63901C9.59777 1.31794 12.6122 -0.000834505 15.7692 0.00293776L16.9005 0.00444583C20.7806 0.00934978 24.3862 2.00713 26.4477 5.29428Z" fill="url(#paint1_radial_25460_19184)" />
            <path d="M24.9858 10.518C24.9687 10.5179 23.257 10.4834 21.7958 9.41472C20.5238 8.48449 20.0837 6.46375 20.0139 5.71198V5.61084H16.9146V18.9812C16.9146 20.5884 15.607 21.8959 13.9998 21.8959C12.3927 21.8959 11.0851 20.5884 11.0851 18.9812C11.0851 17.3739 12.3927 16.0663 13.9998 16.0663H14.9776V12.967H13.9998C10.6838 12.967 7.98584 15.6649 7.98584 18.9812C7.98584 22.2973 10.6838 24.9952 13.9998 24.9952C17.316 24.9952 20.0139 22.2973 20.0139 18.9812V11.9502C22.26 13.5684 24.6976 13.6173 24.976 13.6173C24.9764 13.6173 24.9769 13.6173 24.9772 13.6173L24.9757 10.518H24.9858Z" fill="white" />
            <defs>
                <radialGradient id="paint0_radial_25460_19184" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.5997 22.6283) scale(19.0994 20.2587)">
                    <stop stopColor="#C6C6C6" />
                    <stop offset="0.3682" stopColor="#6D6D6D" />
                    <stop offset="0.6815" stopColor="#999999" />
                    <stop offset="0.8346" stopColor="#A6A6A6" />
                </radialGradient>
                <radialGradient id="paint1_radial_25460_19184" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.22243 31.5111) scale(29.4232 29.4232)">
                    <stop />
                    <stop offset="0.8107" stopColor="#3B3B3B" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export default PkbTiktokIcon;