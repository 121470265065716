import React from "react";
import { useNavigate } from "react-router-dom";
import PkbLargeIcon from "../../component/common/icon/pickelball-large-icon";
import PkbContact from "../../component/contact/contact";
import PkbFooter from "../../component/footer/footer";
import PkbHeader from "../../component/header/header";
import PkbNew from "../../component/new/new";
import PkbPartner from "../../component/partner/partner";
import ProductHot from "../../component/product-hot/product-hot";
import PkbBannerSlideshow from "../../component/slideshow/banner-slide";
import TournamentLive from "../../component/tournament-live/tournament-live";
import { UseTournamentList } from "../tournament/service";
import { UseMainBannerImg } from "./service";

const Home = () => {
    const { isPending, isError, data, error, refetch } = UseMainBannerImg({ page: 1, limit: 99 , cid: 8});
    const navigate = useNavigate(); 
    const imgsHome = UseMainBannerImg({ page: 1, limit: 99 , cid: 25});
    const tournament = UseTournamentList();

    return (<>
        <div>
            <div className="relative ">
                <div>
                    <PkbBannerSlideshow data={data?.data} imgStyle={{height: 543}} size={data?.data?.length}></PkbBannerSlideshow>
                </div>
                <div className="p-6 absolute top-0 w-full">
                    <PkbHeader></PkbHeader>
                    {/* <div className="text-white flex items-center justify-center pkb-banner-title ">
                        <div className="max-w-[853px]">
                            <h2 className="font-bold text-5xl leading-[64px]">Khám phá thế giới tin tức và giải đấu Pickleball hấp dẫn</h2>
                            <p className="text-lg font-normal mt-4 mb-8 leading-[27px]">Tại Vpickleball, chúng tôi không chỉ cung cấp thông tin mới nhất, mà còn cập nhật đầy đủ các giải đấu pickleball đáng chú ý.
                                Đồng hành cùng chúng tôi để không bỏ lỡ bất kỳ sự kiện quan trọng nào.
                            </p>
                            <Button className="bg-white text-black py-4 px-12 "
                                style={{
                                    'padding': '16px 48px',
                                    'color': '#000',
                                    'backgroundColor': '#fff',
                                    'textTransform': 'none'
                                }}
                                variant="contained" color="inherit">Khám phá ngay</Button>
                        </div>
                    </div> */}
                </div>
            </div>
            <div>
                <div className="pkb-hotnew-bg relative">
                    <div className="absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]">
                        <PkbLargeIcon></PkbLargeIcon>
                    </div>
                    <PkbNew></PkbNew>
                    <TournamentLive data={tournament?.data?.results} onClick={(id) => {navigate(`/tournament/detail/${id}`)}}></TournamentLive>
                </div>
                <div className="bg-white  py-16 px-[60px]">
                    <div>
                    <PkbBannerSlideshow data={imgsHome?.data?.data} containerStyle={{height: 458}} size={imgsHome?.data?.data?.length} imgStyle={{borderRadius: '16px', height: 458}}></PkbBannerSlideshow>
                    </div>
                </div>
                <PkbPartner></PkbPartner>
                {/* <ProductHot></ProductHot> */}
                <PkbContact></PkbContact>
                <PkbFooter></PkbFooter>
            </div>
        </div>
    </>)

}

export default Home;