export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const formatDateToStr = (time) => {
    if (!time) { return ''}

    const newTime = parseInt(time +'000');
    const date = new Date(newTime)

    if (!date) { return ''}

    const hours = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
    const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
    const year = date.getFullYear();
    const month = date.getMonth() >= 10 ? date.getMonth() : '0' + date.getMonth();
    const day = date.getDay() >= 10 ? date.getDay() : '0' + date.getDay();

    const dateStr = `${hours}:${minute} - ${day} Thg ${month}, ${year}`

    return dateStr;
}
