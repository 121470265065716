import { Tooltip } from "@mui/material";

const PkbPaginationTitle = (props) => {
    const onNextPage = () => {
        const newPage = props?.dataLength >= props?.limit ? props?.page + 1 : props?.page

        if (newPage ===  props?.page) { return }

        props?.onChange(newPage)
    }

    const onPrevPage = () => {
        const newPage = props?.page > 1 ? props?.page - 1 : props.page

        if (newPage ===  props?.page) { return }

        props?.onChange(newPage)
    }

    return (
        <>
            <div>
                <Tooltip title="Về trước" onClick={() => onPrevPage()}>
                    <span className="cursor-pointer z-10" >
                        <svg className="inline" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="white" />
                            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#919EAB" />
                            <g clipPath="url(#clip0_25468_2485)">
                                <path opacity="0.2" d="M21 25.5L13.5 18L21 10.5V25.5Z" fill="#637381" />
                                <path d="M21 25.5L13.5 18L21 10.5V25.5Z" stroke="#637381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_25468_2485">
                                    <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </span>
                </Tooltip>

                <Tooltip title="Tiếp theo" onClick={() => onNextPage()}>
                    <span className="cursor-pointer z-10">
                        <svg className="inline ml-4" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="white" />
                            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#919EAB" />
                            <g clipPath="url(#clip0_25468_2487)">
                                <path opacity="0.2" d="M15 10.5L22.5 18L15 25.5V10.5Z" fill="#637381" />
                                <path d="M15 10.5L22.5 18L15 25.5V10.5Z" stroke="#637381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_25468_2487">
                                    <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </span>
                </Tooltip>

            </div>
        </>
    )
}

export default PkbPaginationTitle;