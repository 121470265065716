import { Card, CardContent, CardHeader, Chip, Pagination } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import PkbBreadcumbs from "../../component/common/breadcrumbs"
import PkbTab from "../../component/common/tab"
import PkbTailFlag from "../../component/common/tail-flag"
import PkbHeader from "../../component/header/header"
import { UseGetArticles } from "./service"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatDateToStr } from "../../share/common"
import PkbPaperIcon from "../../component/common/icon/paper-icon"
import PkbFooter from "../../component/footer/footer"
import { useNavigate } from "react-router-dom"

const PkdNewsTabs = [
    {
        id: 1,
        label: 'Tin tức & Sự kiện Pickleball'
    },
    {
        id: 2,
        label: 'Bài viết hướng dẫn'
    },
    {
        id: 3,
        label: 'Dạy chơi Pickleball'
    }
]

const News = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pagCount, setPagCount] = useState(1);
    const [tab, setTab] = useState(1);
    const [firstNew, setFirstNew] = useState({
        id: '',
        publicPath: '',
        avatar: '',
        time_update: '',
        vn_detail: ''
    });
    const { isPending, isError, data, error, refetch } = UseGetArticles({ page, limit: 9 });


    useEffect(() => {
        const Breadcrumbs = [
            {
                title: 'Trang chủ',
                link: '/home'
            },
            {
                title: 'Tin tức',
                link: '/news'
            }
        ]
        dispatch({ type: 'set', breadcrumbs: Breadcrumbs })
    }, [])

    useEffect(() => {
        if (!data?.data) {
            setPagCount(0)

            return
        }

        setFirstNew(data?.data[0])
        setPagCount(Math.ceil(data?.total / 9))
    }, [data])

    const handleChange = (id) => {
        setTab(id);
    };

    const goToDetail = (id) => {
        navigate(`/news/${id}`)
    }

    return (
        <div className="pkb-gray-bg">
            <div className="p-6 w-full ">
                <PkbHeader></PkbHeader>
                <div className="pt-16 px-[60px]">
                    <PkbBreadcumbs></PkbBreadcumbs>
                    <div className="grid gap-x-6 pkb-hotnew-column mt-12">
                        <div>
                            <PkbTailFlag color="#0162AC" label="Tin tức & Sự kiện"></PkbTailFlag>
                            <div className="mt-8">
                                <PkbTab tabs={PkdNewsTabs} onChange={(id) => handleChange(id)} activeId={tab}></PkbTab>
                            </div>
                            <div className="text-white h-[480px] rounded-[17.25px] overflow-hidden relative mt-8 cursor-pointer"
                                onClick={() => goToDetail(firstNew.id)}>
                                <img className='w-full h-full' src={firstNew.publicPath + firstNew.avatar} alt="">
                                </img>
                                <div className='absolute bottom-6 left-6 text-left'>
                                    <div className='text-[20px]'>
                                        <Chip className="pkb-danger-bg pbk-text-white mr-2" label="Tin nổi bật" />
                                        <AccessTimeIcon></AccessTimeIcon>
                                        <span>{formatDateToStr(firstNew.time_update)}</span>
                                    </div>
                                    <div className='max-h-[96px] overflow-hidden pr-6'>
                                        <span className='font-bold text-[32px] ' dangerouslySetInnerHTML={{ __html: firstNew.vn_detail }}></span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-8 mt-8">
                                {data?.data?.length ?
                                    data?.data.map((item, idx) => {
                                        if (idx === 0) { return; }

                                        return (
                                            <Card style={{ borderRadius: '12px' }}>
                                                <CardContent style={{ padding: 0 }}>
                                                    <div>
                                                        <img src={item.publicPath + item.avatar} style={{ width: '100%', height: '212px' }} />
                                                        <div className="p-3">
                                                            <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium cursor-pointer'
                                                                onClick={() => goToDetail(item.id)}>
                                                                {item.vn_title}
                                                            </h1>
                                                            <p className='text-clip max-h-12 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.vn_detail }}>
                                                            </p>
                                                            <div className='text-sm leading-[40px] pkb-text-secondary'>
                                                                <AccessTimeIcon></AccessTimeIcon>
                                                                <span>{formatDateToStr(item.time_update)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    })
                                    : <></>}
                            </div>
                            <div className="flex justify-center pb-12 mt-8">
                                <Pagination page={page}
                                    count={pagCount}
                                    onChange={(event, value) => {
                                        setPage(value)
                                    }}
                                    variant="outlined" shape="rounded" />
                            </div>
                        </div>
                        <div>
                            <Card style={{ borderRadius: '16px' }}>
                                <CardHeader
                                    className="pkb-blue-gradient h-[68px]"
                                    title={
                                        <p>
                                            <span><PkbPaperIcon color="#fff"/>
                                            </span>
                                            <span className="text-[32px] font-bold ml-2 text-white">
                                                Tin tức & Sự kiện nổi bật
                                            </span>
                                        </p>
                                    }></CardHeader>
                                <CardContent className="p-4">
                                    {data?.data ? data?.data.map((item, idx) => {
                                        if (idx === 0) { return null; }
                                        return (
                                            <Card className={'h-fit z-10 mt-4 pkb-primay-border'}>
                                                <CardContent className='grid pkb-hotnew-rightitem-column pkb-8px-padding'>
                                                    <div className="h-32">
                                                        <img src={item.publicPath + item.avatar} alt="" width={128} height={128} className="h-32 w-32" />
                                                    </div>
                                                    <div className='max-h-32 text-left ml-3'>
                                                        <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium cursor-pointer'
                                                            onClick={() => goToDetail(item.id)}>
                                                            {item.vn_title}
                                                        </h1>
                                                        <p className='text-clip max-h-12 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.vn_detail }}>
                                                            {/* {item.vn_detail} */}
                                                        </p>
                                                        <div className='text-sm leading-[40px] pkb-text-secondary'>
                                                            <AccessTimeIcon></AccessTimeIcon>
                                                            <span>{formatDateToStr(item.time_update)}</span>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    }) : <></>}
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <PkbFooter></PkbFooter>
        </div>
    )
}

export default News