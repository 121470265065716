import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Tournament from './pages/tournament';
import TournamentDetail from './pages/tournament/detail';
import TournamentPayment from './pages/tournament/payment';
import { Provider } from 'react-redux'
import store from './share/store';
import News from './pages/news';
import NewDetail from './pages/news/detail';


const queryClient = new QueryClient()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <React.StrictMode>
        <HashRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/tournament" element={<Tournament />}>
            </Route>
            <Route path="tournament/detail/:id" element={<TournamentDetail />} />
            <Route path="tournament/detail/payment" element={<TournamentPayment />} />
            <Route path="/news" element={<News />}></Route>
            <Route path="/news/:id" element={<NewDetail />}></Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/*" element={<App />} />
          </Routes>
        </HashRouter>
      </React.StrictMode>
    </Provider>
  </QueryClientProvider>

);

