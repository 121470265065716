import { Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircleDotIcon from "./icon/circle-dot-icon";



const PkbBreadcumbs = (props) => {
    const navigate = useNavigate(); 
    // @ts-ignore
    const breadcrumbs = useSelector((state) => state.breadcrumbs)

    return (
        <Stack spacing={2}>
            <Breadcrumbs separator={<CircleDotIcon></CircleDotIcon>} aria-label="breadcrumb" className="text-base">
                {breadcrumbs?.length ? breadcrumbs.map((breadcrumb, idx) => {
                    if (idx === breadcrumbs.length - 1) {
                        return (
                            <Typography key={idx} className="pkb-primary-color cursor-pointer">{breadcrumb.title}</Typography>
                        )
                    }
                    return (
                        <a key={idx} className="cursor-pointer hover:no-underline hover:text-zinc-500" color="inherit" onClick={() => navigate(breadcrumb.link)}>
                            {breadcrumb.title}
                        </a>
                    )
                }) : <></>}
            </Breadcrumbs>
        </Stack>
    )
}

export default PkbBreadcumbs;