import { Card, CardContent } from "@mui/material"
import BannerStatus from "../common/banner-status"
import PkbPaginationTitle from "../common/pagination-title"
import PkbTailFlag from "../common/tail-flag"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PkbBtn from "../common/button";
import PkbFilter from "../common/filter";



const TournamentLive = (props) => {
    const renderItem = () => {
        const items = [];

        if (!props?.data?.length) { return items; }

        props?.data.forEach(item => {
            let tourItem = (<Card className="z-10">
                <CardContent className=" relative p-0" style={{ 'padding': 0 }}>
                    <div>
                        <div>
                            <img src={item.image} alt="" className="w-full" />
                        </div>
                        <div className="p-3">
                            <h1 className="text-base font-medium text-left">{item.name}</h1>
                            <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                <div>
                                    <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
                                </div>
                                <div>
                                    <p className="text-base text-left">{item.pickleball_court}</p>
                                </div>
                                <div>
                                    <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
                                </div>
                                <div>
                                    <p className="text-base text-left">{item.start_date} - {item.end_date}</p>
                                </div>
                                <div>
                                    <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
                                </div>
                                <div>
                                    <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">{item.numbers_golfer || 0}/{item.numbers_club || 0}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BannerStatus
                        color={props?.footerColor || ''}
                        label={props?.footerTxt || 'Đang diễn ra'}
                        nextIcon={props?.footerIcon || null}
                        btnTxt={props?.footerBtnTxt || 'Xem giải'}
                        onClick={() => props?.onClick(item.id)}
                    ></BannerStatus>
                </CardContent>
            </Card>)
            items.push(tourItem)
        })
        // for (let i = 0; i < props?.data.length; i++) {
        //     let item = (<Card className="z-10">
        //         <CardContent className=" relative p-0" style={{ 'padding': 0 }}>
        //             <div>
        //                 <div>
        //                     <img src={`${process.env.PUBLIC_URL}/giaidau.png`} alt="" className="w-full" />
        //                 </div>
        //                 <div className="p-3">
        //                     <h1 className="text-base font-medium text-left">GIẢI ĐẤU 1</h1>
        //                     <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
        //                         <div>
        //                             <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
        //                         </div>
        //                         <div>
        //                             <p className="text-base text-left">Câu lạc bộ Lan Anh (Số 291 Cách Mạng Tháng Tám, Phường 12, Quận 10, TPHCM)</p>
        //                         </div>
        //                         <div>
        //                             <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
        //                         </div>
        //                         <div>
        //                             <p className="text-base text-left">10 thg 10, 2024 - 12 thg 10, 2024</p>
        //                         </div>
        //                         <div>
        //                             <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
        //                         </div>
        //                         <div>
        //                             <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">10/100</a></p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <BannerStatus
        //                 color={props?.footerColor || ''}
        //                 label={props?.footerTxt || 'Đang diễn ra'}
        //                 nextIcon={props?.footerIcon || null}
        //                 btnTxt={props?.footerBtnTxt || 'Xem giải'}
        //                 onClick={() => props?.onClick()}
        //             ></BannerStatus>
        //         </CardContent>
        //     </Card>)
        //     items.push(item)
        // }

        return items
    }

    const getFooterIcon = (status) => {
        let icon = null
        if (status === 3) {
            icon = <PlayArrowIcon className="text-white" color="inherit"></PlayArrowIcon>
        }

        return icon
    }
    return (
        <>
            <div className="py-16 px-[60px] relative">
                <div className="float z-10">
                    <div className="float-left w-9/12 text-left">
                        <PkbTailFlag color={props.tailColor} label={props?.label || 'Giải đấu'}
                            icon={props?.tailIcon}
                            customIcon={props?.tailCustomIcon}></PkbTailFlag>
                    </div>
                    <div className="float-right">
                        <PkbPaginationTitle></PkbPaginationTitle>
                    </div>
                    <div className="clear-both"></div>
                </div>
                {props?.showFilterBtn ?
                    <div className="text-left mt-8">
                        <PkbBtn btnTxt="Đang diễn ra" style={{ 'padding': '6px 16px' }}></PkbBtn>
                    </div> : <></>}

                <div className={!props?.isDetail ? 'block' : 'hidden'}>
                    <div className={'grid mt-8 grid-cols-2 gap-8 z-10 '}>
                        {props?.data?.length ?
                            props?.data.map((item, idx) => {
                                return (
                                    <Card className="z-10" key={idx}>
                                        <CardContent className="h-[236px] relative p-0">
                                            <div className="grid grid-cols-2" style={{ gridTemplateColumns: '200px auto' }}>
                                                <div>
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="p-3 pt-0">
                                                    <h1 className="text-base font-medium text-left">{item.name}</h1>
                                                    <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                                        <div>
                                                            <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
                                                        </div>
                                                        <div>
                                                            <p className="text-base text-left">{item.pickleball_court}</p>
                                                        </div>
                                                        <div>
                                                            <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
                                                        </div>
                                                        <div>
                                                            <p className="text-base text-left">{item.start_date} - {item.end_date}</p>
                                                        </div>
                                                        <div>
                                                            <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
                                                        </div>
                                                        <div>
                                                            <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">{item.numbers_golfer || 0}/{item.numbers_club || 0}</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <BannerStatus
                                                label={item?.status === 3 ? 'Giải đấu sắp diễn ra' : item?.status === 2 ? 'Giải đấu đang diễn ra' : 'Giải đấu đã kết thúc'}
                                                color={item?.status === 3 ? '#FCC134' : item?.status === 1 ? '#919EAB' : ''}
                                                nextIcon={getFooterIcon(item?.status)}
                                                btnTxt={item?.status === 3 ? 'Đăng ký' : item?.status === 2 ? 'Xem giải' : 'Xem kết quả'}
                                                classes="absolute bottom-0 left-0"
                                                onClick={() => props?.onClick(item.id)}></BannerStatus>
                                        </CardContent>
                                    </Card>
                                )
                            })
                            : <></>}
                        {/* <Card className="z-10">
                            <CardContent className="h-[236px] relative p-0">
                                <div className="grid grid-cols-2" style={{ gridTemplateColumns: '200px auto' }}>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/giaidau.png`} alt="" />
                                    </div>
                                    <div className="p-3 pt-0">
                                        <h1 className="text-base font-medium text-left">GIẢI ĐẤU 1</h1>
                                        <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                            <div>
                                                <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Câu lạc bộ Lan Anh (Số 291 Cách Mạng Tháng Tám, Phường 12, Quận 10, TPHCM)</p>
                                            </div>
                                            <div>
                                                <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">10 thg 10, 2024 - 12 thg 10, 2024</p>
                                            </div>
                                            <div>
                                                <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">10/100</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <BannerStatus
                                    label={props?.footerTxt || 'Đang diễn ra'}
                                    btnTxt="Xem giải"
                                    classes="absolute bottom-0 left-0"></BannerStatus>
                            </CardContent>
                        </Card>
                        <Card className="z-10">
                            <CardContent className="h-[236px] relative p-0">
                                <div className="grid grid-cols-2" style={{ gridTemplateColumns: '200px auto' }}>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/giaidau.png`} alt="" />
                                    </div>
                                    <div className="p-3 pt-0">
                                        <h1 className="text-base font-medium text-left">GIẢI ĐẤU 1</h1>
                                        <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                            <div>
                                                <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Câu lạc bộ Lan Anh (Số 291 Cách Mạng Tháng Tám, Phường 12, Quận 10, TPHCM)</p>
                                            </div>
                                            <div>
                                                <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">10 thg 10, 2024 - 12 thg 10, 2024</p>
                                            </div>
                                            <div>
                                                <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">10/100</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <BannerStatus
                                    label="Đang diễn ra"
                                    btnTxt="Xem giải"
                                    classes="absolute bottom-0 left-0"></BannerStatus>
                            </CardContent>
                        </Card>
                        <Card className="z-10">
                            <CardContent className="h-[236px] relative p-0">
                                <div className="grid grid-cols-2" style={{ gridTemplateColumns: '200px auto' }}>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/giaidau.png`} alt="" />
                                    </div>
                                    <div className="p-3 pt-0">
                                        <h1 className="text-base font-medium text-left">GIẢI ĐẤU 2</h1>
                                        <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                            <div>
                                                <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Câu lạc bộ Lan Anh (Số 291 Cách Mạng Tháng Tám, Phường 12, Quận 10, TPHCM)</p>
                                            </div>
                                            <div>
                                                <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">10 thg 10, 2024 - 12 thg 10, 2024</p>
                                            </div>
                                            <div>
                                                <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">10/100</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <BannerStatus
                                    label="Sắp diễn ra"
                                    btnTxt="Đăng ký"
                                    color="#FCC134"
                                    nextIcon={<PlayArrowIcon className="text-white" color="inherit"></PlayArrowIcon>}
                                    classes="absolute bottom-0 left-0"></BannerStatus>
                            </CardContent>
                        </Card>
                        <Card className="z-10">
                            <CardContent className="h-[236px] relative p-0">
                                <div className="grid grid-cols-2" style={{ gridTemplateColumns: '200px auto' }}>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/giaidau.png`} alt="" />
                                    </div>
                                    <div className="p-3 pt-0">
                                        <h1 className="text-base font-medium text-left">GIẢI ĐẤU 2</h1>
                                        <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: '16px auto' }}>
                                            <div>
                                                <LocationOnIcon className="text-base" fontSize="inherit"></LocationOnIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Câu lạc bộ Lan Anh (Số 291 Cách Mạng Tháng Tám, Phường 12, Quận 10, TPHCM)</p>
                                            </div>
                                            <div>
                                                <CalendarTodayIcon className="text-base" fontSize="inherit"></CalendarTodayIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">10 thg 10, 2024 - 12 thg 10, 2024</p>
                                            </div>
                                            <div>
                                                <GroupIcon className="text-base" fontSize="inherit"></GroupIcon>
                                            </div>
                                            <div>
                                                <p className="text-base text-left">Đã đăng ký: <a className="pkb-primary-color">10/100</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <BannerStatus
                                    label="Sắp diễn ra"
                                    btnTxt="Đăng ký"
                                    color="#FCC134"
                                    nextIcon={<PlayArrowIcon className="text-white" color="inherit"></PlayArrowIcon>}
                                    classes="absolute bottom-0 left-0"></BannerStatus>
                            </CardContent>
                        </Card> */}
                    </div>
                </div>
                {props?.showFilter ?
                    <PkbFilter></PkbFilter>
                    : <></>}
                <div className={props?.isDetail ? 'block' : 'hidden'}>
                    <div className={'grid mt-8 grid-cols-3 gap-8 z-10 '}>
                        {renderItem()}
                    </div>
                </div>

            </div>
        </>
    )
}

export default TournamentLive