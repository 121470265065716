import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Button } from '@mui/material';
import { useEffect, useRef } from 'react';

const BannerStatus = (props) => {
    const lElment = useRef(null);
    const rElment = useRef(null);

    useEffect(() => {
        const rElementWidth = rElment.current.clientWidth
        lElment.current.style.width = `calc( 100% - ${rElementWidth + 1}px )`
    }, [])
    return (
        <>
            <div className={'h-9 w-full ' + props?.classes}>
                <div className='float w-full h-full'>
                    <div ref={lElment} className="float-left pkb-status-banner h-full pl-[10px] flex items-center"
                        style={{ 'backgroundColor': props?.color || '#FF211A', 'width': 'calc( 100% - 104px )' }}>
                        <p className='text-left'>
                            {props?.nextIcon ?
                                props?.nextIcon
                                //<ChangeHistoryIcon></ChangeHistoryIcon> 
                                :
                                <span className="inline-block mr-2 h-4 w-4 rounded-[50%] bg-white"></span>}

                            <span className="text-white text-base font-medium">{props.label}</span>
                        </p>
                    </div>
                    <div className="float-right" ref={rElment}>
                        <Button variant="text"
                            className='text-black p-[7px]'
                            onClick={() => props?.onClick()}
                            endIcon={<ChangeHistoryIcon className='rotate-90'></ChangeHistoryIcon>}>{props.btnTxt}</Button>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>
        </>
    )
}

export default BannerStatus