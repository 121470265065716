import { Card, CardContent } from "@mui/material";
import PkbFbIcon from "../common/icon/face-icon";
import PkbInstaIcon from "../common/icon/instagram-icon";
import PkbMapIcon from "../common/icon/map-icon";
import PkbTiktokIcon from "../common/icon/tiktok-icon";
import PkbYtIcon from "../common/icon/youtube-icon";
import FooterLogo from "./footer-logo";

const PkbAboutMe = () => {
    return (
        <>
            <Card className="px-6 py-12 " style={{'borderRadius': '16px'}}>
                <CardContent className="text-base text-left pkb-p-0" >
                    <div className="grid gap-x-6" style={{gridTemplateColumns: '200px auto auto 289px'}}>
                        <div>
                            <FooterLogo></FooterLogo>
                        </div>
                        <div>
                            <h3 className="pkb-primary-color font-medium">CÔNG TY CỔ PHẦN VPICKLEBALL</h3>
                            <p className="mt-1">MST: 0110855939</p>
                            <p className="mt-1">Địa chỉ ĐKKD: 38 - D3 phố Hoàng Ngân Trung Hòa, Cầu Giấy, Hà Nội</p>
                            <p className="mt-1">VPGD: 38 - D3 phố Hoàng Ngân Trung Hòa, Cầu Giấy, Hà Nội</p>
                            <p className="mt-1">Hotline: 0788 599 399 (24/7)</p>
                            <p className="mt-1">Email: info@igb.vn</p>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#" className="pkb-primary-color font-medium">Trang chủ</a></li>
                                <li className="mt-1"><a href="#">Giới thiệu</a></li>
                                <li className="mt-1"><a href="#">Giải đấu</a></li>
                                <li className="mt-1"><a href="#">Câu lạc bộ</a></li>
                                <li className="mt-1"><a href="#">Shop</a></li>
                                <li className="mt-1"><a href="#">Liên hệ</a></li>
                            </ul>
                        </div>
                        <div>
                            <h4>THEO DÕI TRÊN MẠNG XÃ HỘI</h4>
                            <div className="mt-4 mb-6">
                                <a href="#" className="inline-block mr-1"><PkbFbIcon></PkbFbIcon></a>
                                <a href="#" className="inline-block mr-1"><PkbTiktokIcon></PkbTiktokIcon></a>
                                <a href="#" className="inline-block mr-1"><PkbYtIcon></PkbYtIcon></a>
                                <a href="#" className="inline-block mr-1"><PkbInstaIcon></PkbInstaIcon></a>
                            </div>
                            <a href="#" className="map-link"><span className="mr-2">BẢN ĐỒ VĂN PHÒNG VPICKLEBALL</span><PkbMapIcon></PkbMapIcon></a>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}
export default PkbAboutMe;