import { Button } from "@mui/material"

const PkbTab = (props) => {
    return (
        <>
            <div className="text-left">
                {props?.tabs?.length ?
                    props?.tabs.map(tab => {
                        if (tab?.id === props?.activeId) {
                            return (
                                <Button
                                    key={'tab' + tab?.id}
                                    variant="contained"
                                    className="mr-4 normal-case w-fit"
                                    style={{
                                        'marginRight': '16px',
                                        'textTransform': 'none'
                                    }}
                                    >{tab.label}</Button>
                            )
                        } else {
                            return (
                                <Button
                                    key={'tab' + tab?.id}
                                    variant="outlined"
                                    className="mr-4 normal-case w-fit"
                                    style={{
                                        'marginRight': '16px',
                                        'textTransform': 'none'
                                    }}
                                    onClick={() => props?.onChange(tab.id)}>{tab.label}</Button>
                            )
                        }
                    })
                    : <></>}
            </div>

        </>
    )
}

export default PkbTab;