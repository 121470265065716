import { Button, Card, CardContent, CardHeader } from "@mui/material";
import PkbBreadcumbs from "../../component/common/breadcrumbs";
import PkbAndroidIcon from "../../component/common/icon/android-logo-icon";
import CircleDotIcon from "../../component/common/icon/circle-dot-icon";
import PkbIosIcon from "../../component/common/icon/ios-logo-icon";
import PkbFooter from "../../component/footer/footer";
import PkbHeader from "../../component/header/header";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Breadcrumbs = [
    {
        title: 'Trang chủ',
        link: '/home'
    },
    {
        title: 'Giải đấu',
        link: '/tournament'
    },
    {
        title: 'Giải đấu Pickleball mở rộng khu vực Châu Á Thái Bình Dương',
        link: '/tournament/detail'
    },
    {
        title: 'Thanh toán',
        link: '/tournament/detail/payment'
    }
]

const TournamentPayment = () => {
    // @ts-ignore
    const tourPayment = useSelector((state) => state.tourPayment)
    // @ts-ignore
    const tourRegister = useSelector((state) => state.tourRegister)
    const dispatch = useDispatch()

    useEffect(() => {
        const Breadcrumbs = [
            {
                title: 'Trang chủ',
                link: '/home'
            },
            {
                title: 'Giải đấu',
                link: '/tournament'
            },
            {
                title: tourRegister?.tournament_name,
                link: '/tournament/detail/' + tourRegister?.tournament_id
            },
            {
                title: 'Thanh toán',
                link: '/tournament/detail/payment'
            }
        ]

        dispatch({ type: 'set', breadcrumbs: Breadcrumbs })
    }, [tourRegister])

    return (
        <>
            <div className="pkb-gray-bg">
                <div className=" p-6">
                    <PkbHeader></PkbHeader>
                </div>
                <div className="px-[60px]">
                    <div className="pt-16">
                        <PkbBreadcumbs breadcrumbs={Breadcrumbs}></PkbBreadcumbs>
                    </div>
                    <div className="py-16">
                        <div className="grid grid-cols-2 gap-x-12 pkb-text-primary">
                            <div>
                                <Card style={{ borderRadius: '8px', height: '100%' }}>
                                    <CardHeader
                                        className="pkb-card-header"
                                        title={
                                            <h1 className="text-center text-2xl font-medium">Thông tin đăng ký</h1>
                                        }></CardHeader>
                                    <CardContent style={{ padding: '24px 32px' }}>
                                        <div className="mb-4">
                                            <p className="text-base">Giải đấu</p>
                                            <h2 className="text-[20px] font-medium mt-2">
                                                {tourRegister?.tournament_name}
                                            </h2>
                                        </div>

                                        <div className="mb-4">
                                            <p className="text-base">Nhóm đấu</p>
                                            <h2 className="text-[20px] font-medium mt-2">{tourRegister?.tournament_cate_name}</h2>
                                        </div>
                                        <div className="mb-4">
                                            <p className="text-base">Vận động viên tham dự</p>
                                            <ul className="list-disc list-inside text-gray-600">
                                                <li><span className="text-[20px] font-medium">{tourRegister?.fullname} <CircleDotIcon /> <span>{tourRegister?.phone}</span></span></li>
                                                {tourRegister?.couple ?
                                                    <li><span className="text-[20px] font-medium">{tourRegister?.player2_fullname} <CircleDotIcon /> <span>{tourRegister?.player2_phone}</span></span></li>
                                                    : <></>}
                                            </ul>
                                        </div>
                                        <div className="mb-4">
                                            <p className="text-base">Lệ phí tham dự giải</p>
                                            <h2 className="text-[20px] font-medium">
                                                Vé tham dự giải đấu <span className="font-medium">x{tourRegister?.couple + 1}</span> -
                                                <span className="text-right">{tourPayment?.thongTinCK?.amount} VND</span>
                                            </h2>
                                        </div>
                                        <div className="mb-6">
                                            <h2 className="text-xl font-semibold">Thanh toán</h2>
                                            <p>
                                                Hãy thanh toán trước <span className="font-medium">23h59 - 25 Thg 11, 2024</span> để không bỏ lỡ giải đấu.
                                            </p>
                                        </div>
                                        <p className="mb-6">
                                            Chúc bạn có một giải đấu thành công và nhiều trải nghiệm thú vị!
                                        </p>
                                        <div>
                                            <h2 className="text-xl font-semibold">Tải ứng dụng VPickleball</h2>
                                            <p className="mb-2">Theo dõi và cập nhật thông tin về giải đấu một cách nhanh nhất!</p>
                                            <div className="flex space-x-4 mt-[20px]">
                                                <p>
                                                    <PkbAndroidIcon />
                                                    <span className="font-medium ml-2 mr-2">Android</span>
                                                    <a className="pkb-primary-color" href="https://play.google.com/store/apps/details?id=com.vpickleball">https://play.google.com/store/apps/details?id=com.vpickleball</a>
                                                </p>
                                            </div>
                                            <div className="flex space-x-4 mt-3">
                                                <p>
                                                    <PkbIosIcon />
                                                    <span className="font-medium ml-2 mr-2">IOS</span>
                                                    <a className="pkb-primary-color" href="https://apps.apple.com/us/app/vpickleball/id6737280553">https://apps.apple.com/us/app/vpickleball/id6737280553</a>
                                                </p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className="h-full">
                                <Card style={{ borderRadius: '8px', height: '100%' }}>
                                    <CardHeader className="pkb-card-header" title={<h1 className="text-center text-2xl font-medium">Thông tin thanh toán</h1>}>

                                    </CardHeader>
                                    <CardContent style={{ padding: '24px 32px' }}>
                                        <div className="text-[20px]">
                                            <p className="mb-4">
                                                <span>Số tài khoản:</span>
                                                <span className="font-medium">{tourPayment?.thongTinCK?.STK}</span>
                                            </p>
                                            <p className="mb-4">
                                                <span >Ngân hàng:</span> <span className="font-medium">{tourPayment?.thongTinCK?.tenNH}</span>
                                            </p>
                                            <p className="mb-4">
                                                <span>Chủ tài khoản:</span> <span className="font-medium">{tourPayment?.thongTinCK?.CTK}</span>
                                            </p>
                                            <p>
                                                <span >Nội dung:</span>
                                                <span className="font-medium">Họ tên + số điện thoại + Thanh toán giải đấu qua VPickleball</span>
                                            </p>
                                        </div>
                                        {tourPayment?.thongTinCK?.qrPay ?
                                            <>
                                                <div className="flex justify-center mt-6">
                                                    <img className="rounded-[21px]" src={process.env.PUBLIC_URL + '/qr-payment.png'} alt="" width={341} height={441} />
                                                </div>
                                                <div className="mt-6 text-center">
                                                    <Button variant="outlined" startIcon={<SaveAltIcon />}>Tải xuống QR</Button>
                                                </div>
                                            </>
                                            : <></>}

                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PkbFooter></PkbFooter>
        </>
    )
}

export default TournamentPayment;