const CircleDotIcon = () => {
    return (
        <>
        <svg className="inline-block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25497_82207)">
                <path opacity="0.2" d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z" fill="#1C252E" />
                <path d="M13.0781 12C13.0781 12.5954 12.5954 13.0781 12 13.0781C11.4046 13.0781 10.9219 12.5954 10.9219 12C10.9219 11.4046 11.4046 10.9219 12 10.9219C12.5954 10.9219 13.0781 11.4046 13.0781 12Z" fill="#1C252E" stroke="#1C252E" stroke-width="0.09375" />
            </g>
            <defs>
                <clipPath id="clip0_25497_82207">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
        </>
        
    )
}

export default CircleDotIcon;