import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PkbPaginationTitle from "../common/pagination-title";
import CardContent from '@mui/material/CardContent';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatDateToStr } from '../../share/common';
import { UseGetArticles } from '../../pages/news/service.js';
import { useNavigate } from 'react-router-dom';


const PkbNew = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [firstNew, setFirstNew] = useState({
        publicPath: '',
        avatar: '',
        time_update: '',
        vn_detail: ''
    });
    const { isPending, isError, data, error, refetch } = UseGetArticles({ page, limit: 4 });

    useEffect(() => {
        if (!data?.data?.length) { return }

        setFirstNew(data?.data[0])
    }, [data])

    const goToDetail = (id) => {
        navigate(`/news/${id}`)
    }

    return (
        <>
            <div className="py-16 px-[60px]">
                <div className="float">
                    <div className="float-left w-9/12 text-left">
                        <p>
                            <span><svg className="inline" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_25468_2496)">
                                    <path opacity="0.2" d="M40.5 9H7.5C7.10218 9 6.72064 9.15804 6.43934 9.43934C6.15804 9.72064 6 10.1022 6 10.5V40.5L12 37.5L18 40.5L24 37.5L30 40.5L36 37.5L42 40.5V10.5C42 10.1022 41.842 9.72064 41.5607 9.43934C41.2794 9.15804 40.8978 9 40.5 9ZM21 30H12V18H21V30Z" fill="#0162AC" />
                                    <path d="M6 40.5V10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72064 9.15804 7.10218 9 7.5 9H40.5C40.8978 9 41.2794 9.15804 41.5607 9.43934C41.842 9.72064 42 10.1022 42 10.5V40.5L36 37.5L30 40.5L24 37.5L18 40.5L12 37.5L6 40.5Z" stroke="#0162AC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M27 21H36" stroke="#0162AC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M27 27H36" stroke="#0162AC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 18H12V30H21V18Z" stroke="#0162AC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_25468_2496">
                                        <rect width="48" height="48" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            </span>
                            <span className="text-[32px] font-bold ml-2 pkb-primary-color">
                                Tin tức & Sự kiện nổi bật
                            </span>
                        </p>

                    </div>
                    <div className="float-right">
                        <PkbPaginationTitle onChange={(value) => {
                            setPage(value ? value : 1)
                        }}
                            dataLength={data?.data?.length || 0}
                            limit={3}
                            page={page}></PkbPaginationTitle>
                    </div>
                    <div className="clear-both"></div>
                </div>
                <div className="grid gap-x-6 pkb-hotnew-column">
                    <div className="text-white h-[480px] rounded-[17.25px] overflow-hidden relative cursor-pointer"
                        onClick={() => goToDetail(firstNew.id)}>
                        <img className='w-full h-full' src={firstNew.publicPath + firstNew.avatar} alt="">
                        </img>
                        <div className='absolute bottom-6 left-6 text-left'>
                            <div className='text-[20px]'>
                                <Chip className="pbk-warning-bgcolor pbk-text-white mr-2" label="Tin nổi bật" />
                                <AccessTimeIcon></AccessTimeIcon>
                                <span>{formatDateToStr(firstNew.time_update)}</span>
                            </div>
                            <div className='max-h-[96px] overflow-hidden pr-6'>
                                <span className='font-bold text-[32px] ' dangerouslySetInnerHTML={{ __html: firstNew.vn_detail }}></span>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 grid-rows-3 gap-y-6 '>
                        {data?.data ? data?.data.map((item, idx) => {
                            if (idx === 0) { return null; }
                            return (
                                <Card className={'h-fit z-10'}>
                                    <CardContent className='grid pkb-hotnew-rightitem-column pkb-8px-padding'>
                                        <div className="h-32">
                                            <img src={item.publicPath + item.avatar} alt="" width={128} height={128} className="h-32 w-32" />
                                        </div>
                                        <div className='max-h-32 text-left ml-3'>
                                            <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium cursor-pointer'
                                                onClick={() => goToDetail(item.id)}>
                                                {item.vn_title}
                                            </h1>
                                            <p className='text-clip max-h-12 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.vn_detail }}>
                                                {/* {item.vn_detail} */}
                                            </p>
                                            <div className='text-sm leading-[40px] pkb-text-secondary'>
                                                <AccessTimeIcon></AccessTimeIcon>
                                                <span>{formatDateToStr(item.time_update)}</span>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            )
                        }) : <></>}
                        {/* <Card className={'h-fit z-10'}>
                            <CardContent className='grid  pkb-hotnew-rightitem-column pkb-8px-padding'>
                                <div className="h-32">
                                    <img src={`${process.env.PUBLIC_URL}/hot-new-item.png`} alt="" width={128} height={128} className="h-32 w-32" />
                                </div>
                                <div className='max-h-32 text-left ml-3'>
                                    <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium'>
                                        Joola ra mắt dòng sản phẩm Gen 3 với công nghệ mang tính cách mạng...
                                    </h1>
                                    <p className='text-clip max-h-12 overflow-hidden'>
                                        Rockville, MD (16/4/24) - vui mừng được giới thiệu cuộc cách mạng mới nhất về
                                        công nghệ vợt pickleball cho mùa giải 2024. Không chỉ Perseus và Scorpeus được
                                        nâng cấp mà JOola còn bổ sung thêm hai vợt pickleball mới vào dòng sản phẩm Gen 3...
                                    </p>
                                    <div className='text-sm leading-[40px] pkb-text-secondary'>
                                        <AccessTimeIcon></AccessTimeIcon>
                                        <span>19:00 - 23 Thg 10, 2024</span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={'h-fit z-10'}>
                            <CardContent className='grid  pkb-hotnew-rightitem-column pkb-8px-padding'>
                                <div className="h-32">
                                    <img src={`${process.env.PUBLIC_URL}/hot-new-item.png`} alt="" width={128} height={128} className="h-32 w-32" />
                                </div>
                                <div className='max-h-32 text-left ml-3'>
                                    <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium'>
                                        Joola ra mắt dòng sản phẩm Gen 3 với công nghệ mang tính cách mạng...
                                    </h1>
                                    <p className='text-clip max-h-12 overflow-hidden'>
                                        Rockville, MD (16/4/24) - vui mừng được giới thiệu cuộc cách mạng mới nhất về
                                        công nghệ vợt pickleball cho mùa giải 2024. Không chỉ Perseus và Scorpeus được
                                        nâng cấp mà JOola còn bổ sung thêm hai vợt pickleball mới vào dòng sản phẩm Gen 3...
                                    </p>
                                    <div className='text-sm leading-[40px] pkb-text-secondary'>
                                        <AccessTimeIcon></AccessTimeIcon>
                                        <span>19:00 - 23 Thg 10, 2024</span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={'h-fit z-10'}>
                            <CardContent className='grid  pkb-hotnew-rightitem-column pkb-8px-padding'>
                                <div className="h-32">
                                    <img src={`${process.env.PUBLIC_URL}/hot-new-item.png`} alt="" width={128} height={128} className="h-32 w-32" />
                                </div>
                                <div className='max-h-32 text-left ml-3'>
                                    <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium'>
                                        Joola ra mắt dòng sản phẩm Gen 3 với công nghệ mang tính cách mạng...
                                    </h1>
                                    <p className='text-clip max-h-12 overflow-hidden'>
                                        Rockville, MD (16/4/24) - vui mừng được giới thiệu cuộc cách mạng mới nhất về
                                        công nghệ vợt pickleball cho mùa giải 2024. Không chỉ Perseus và Scorpeus được
                                        nâng cấp mà JOola còn bổ sung thêm hai vợt pickleball mới vào dòng sản phẩm Gen 3...
                                    </p>
                                    <div className='text-sm leading-[40px] pkb-text-secondary'>
                                        <AccessTimeIcon></AccessTimeIcon>
                                        <span>19:00 - 23 Thg 10, 2024</span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PkbNew;