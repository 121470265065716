import { Button } from "@mui/material";
import PkbAboutMe from "./footer-about-me";

const PkbFooter = () => {
    return(
        <>
            <div className="py-6 px-[60px] pkb-banner-container">
                <div className="text-white text-center">
                    <h2 className="text-5xl font-bold mt-16 mb-4">Kết nối cộng đồng Pickleball mọi lúc, mọi nơi!</h2>
                    <p className="text-[18px] mb-8">Tham gia cùng những người yêu thích pickleball, chia sẻ kinh nghiệm và đam mê với cộng đồng sôi động.</p>
                    <Button className="bg-white text-black py-4 px-12 " 
                    style={{
                        'padding': '16px 48px',
                        'color': '#000',
                        'backgroundColor': '#fff',
                        'textTransform': 'none'
                    }}
                    variant="contained" color="inherit">Tham gia ngay</Button>
                </div>
                <div className="mt-16">
                    <PkbAboutMe></PkbAboutMe>
                </div>
            </div>
        </>
    )
}

export default PkbFooter;