import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import PkbBtn from "./button";
import EyeCloseIcon from "./icon/eye-close-icon";
import EyeOpenIcon from "./icon/eye-open-icon";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    backgroundColor: 'background.paper',
    padding: '4',
}

const PkbLoginModal = (props) => {

    const navigate = useNavigate();
    return (
        <Modal
                open={props?.open}
                onClose={() => props?.onClose()}
                size={500}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Modal.Header>
                    <h2 className="text-[20px] font-bold text-center pkb-text-primary">Đăng nhập</h2>
                </Modal.Header>
                <Modal.Body>
                    <form className="mt-3">
                        <div>
                            <label className="block text-base pkb-text-primary">Số điện thoại hoặc email</label>
                            <input type="text" placeholder="Nhập email hoặc số điện thoại" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input" />
                        </div>
                        <div className="mt-8">
                            <label className=" block text-base pkb-text-primary">Mật khẩu</label>
                            <div className="relative">
                                <input type={props?.showPwd ? 'password' : 'text'} placeholder="Nhập mật khẩu" className="w-full px-4 py-2 border rounded-md pkb-input" />
                                <span className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={() => props?.onChangeShowPwd()}>
                                    {props?.showPwd ? <EyeOpenIcon /> : <EyeCloseIcon />}
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 flex justify-end">
                            <a href="#" className="font-medium text-base pkb-primary-color hover:underline">Quên mật khẩu?</a>
                        </div>
                        <div className="mt-12">
                            <PkbBtn btnTxt={'Đăng nhập'} className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                onClick={() => props?.onClose()}></PkbBtn>
                        </div>
                    </form>
                    <p className="mt-4 text-base text-center pkb-text-primary">
                        Bạn chưa có tài khoản? <a onClick={() => { }} className="font-medium pkb-primary-color hover:underline">Đăng ký</a>
                    </p>
                </Modal.Body>
            </Modal>
    )
}

export default PkbLoginModal;