import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PkbBtn from "../../component/common/button";
import EyeCloseIcon from "../../component/common/icon/eye-close-icon";
import EyeOpenIcon from "../../component/common/icon/eye-open-icon";

const RegisterPage = () => {
    const [showPwd, setShowPwd] = useState(false)
    const [showCpPwd, setShowCpPwd] = useState(false)
    const [otpScreen, setOtpScreen] = useState(false)
    const [resend, setResend] = useState(0)
    const navigate = useNavigate();

    useEffect(() => {
        if (resend <= 0) return;
    
        const timerId = setInterval(() => {
            setResend((prevTime) => prevTime - 1);
        }, 1000);
    
        return () => clearInterval(timerId); // Clear the timer on cleanup
      }, [resend]);

    useEffect(() => {
        if (otpScreen) {
            setResend(20)
        }
    }, [otpScreen])
    return (
        <>
            <div className="float p-[60px] h-screen">
                <div className="float-left w-[762px] h-full">
                    <img className="rounded-2xl h-full w-full" src={`${process.env.PUBLIC_URL}/login-bg.png`} alt="" />
                </div>
                <div className="float-left h-full ml-[58px] flex items-center">
                    <div className="w-full  p-6 bg-white h-fit">
                        {!otpScreen ?
                            <div>
                                <h2 className="text-[32px] font-bold text-center pkb-text-primary">Đăng ký <span className="pkb-primary-color">VPickleball</span></h2>
                                <form className="mt-12">
                                    <div>
                                        <label className="block text-base pkb-text-primary">Họ và tên</label>
                                        <input type="text" placeholder="Nhập họ và tên" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input" />
                                    </div>
                                    <div className="mt-8">
                                        <label className="block text-base pkb-text-primary">Số điện thoại hoặc email</label>
                                        <input type="text" placeholder="Nhập email hoặc số điện thoại" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input" />
                                    </div>
                                    <div className="mt-8">
                                        <label className=" block text-base pkb-text-primary">Mật khẩu</label>
                                        <div className="relative">
                                            <input type={!showPwd ? 'password' : 'text'} placeholder="Nhập mật khẩu" className="w-full px-4 py-2 border rounded-md pkb-input" />
                                            <span className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={() => setShowPwd(!showPwd)}>
                                                {!showPwd ? <EyeOpenIcon /> : <EyeCloseIcon />}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <label className=" block text-base pkb-text-primary">Nhập lại mật khẩu</label>
                                        <div className="relative">
                                            <input type={!showCpPwd ? 'password' : 'text'} placeholder="Nhập lại mật khẩu *" className="w-full px-4 py-2 border rounded-md pkb-input" />
                                            <span className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={() => setShowCpPwd(!showCpPwd)}>
                                                {!showCpPwd ? <EyeOpenIcon /> : <EyeCloseIcon />}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex justify-end">
                                        <p>
                                            <Checkbox style={{ 'paddingLeft': '0' }} />
                                            Tôi đồng ý với
                                            <a href="#" className="font-medium text-base pkb-primary-color hover:underline"> Điều khoản Dịch vụ, Chính sách Bảo mật.</a>
                                        </p>
                                    </div>
                                    <div className="mt-12">
                                        <PkbBtn btnTxt={'Đăng ký'} className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700" onClick={() => setOtpScreen(true)}></PkbBtn>
                                    </div>
                                </form>
                                <p className="mt-4 text-base text-center pkb-text-primary">
                                    Bạn đã có tài khoản? <a onClick={() => navigate('/login')} className="font-medium pkb-primary-color hover:underline">Đăng nhập</a>
                                </p>
                            </div>
                            :
                            <div>
                                <div className="w-full max-w-md p-6 bg-white">
                                    <h2 className="text-[32px] font-bold text-center pkb-text-primary mb-12">Xác nhận OTP</h2>
                                    <p className="text-center pkb-text-primary mb-[10px] text-base">Mã xác thực được gửi đến số <span className="pkb-primary-color font-semibold">0987654321</span></p>
                                    <p className="text-center pkb-text-primary mb-6 text-base">Bạn vui lòng nhập mã số gồm 6 chữ số dưới đây</p>

                                    <div className="flex justify-center space-x-4 mb-6">
                                        <input type="text" maxLength={1} className="w-12 h-12 text-[20px] text-center border border-gray-300 rounded-md pkb-input pkb-otp-input" placeholder="-" />
                                        <input type="text" maxLength={1} className="w-12 h-12 text-[20px] text-center border border-gray-300 rounded-md pkb-input pkb-otp-input" placeholder="-" />
                                        <input type="text" maxLength={1} className="w-12 h-12 text-[20px] text-center border border-gray-300 rounded-md pkb-input pkb-otp-input" placeholder="-" />
                                        <input type="text" maxLength={1} className="w-12 h-12 text-[20px] text-center border border-gray-300 rounded-md pkb-input pkb-otp-input" placeholder="-" />
                                        <input type="text" maxLength={1} className="w-12 h-12 text-[20px] text-center border border-gray-300 rounded-md pkb-input pkb-otp-input" placeholder="-" />
                                        <input type="text" maxLength={1} className="w-12 h-12 text-[20px] text-center border border-gray-300 rounded-md pkb-input pkb-otp-input" placeholder="-" />
                                    </div>

                                    <p className="text-center pkb-text-primary mb-2 text-base">Bạn không nhận được OTP? </p>
                                    <p className="pkb-primary-color hover:underline text-base font-medium mb-12 text-center"><a href="#" >Gửi lại mã <span className="text-sm">(00:{resend >= 10 ? resend : '0' + resend})</span></a></p>

                                    {/* <button type="submit" className="w-full py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700">Xác nhận</button> */}
                                    <PkbBtn btnTxt={'Xác nhận'} style={{ paddingTop : '14px', paddingBottom : '14px'}} type="submit" className="w-full px-4 py-3.5 text-white bg-blue-600 rounded-md hover:bg-blue-700"></PkbBtn>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div className="clear-both"></div>
            </div>
        </>
    )
}

export default RegisterPage;