import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PkbBtn from "../../component/common/button";
import EyeCloseIcon from "../../component/common/icon/eye-close-icon";
import EyeOpenIcon from "../../component/common/icon/eye-open-icon";

const LoginPage = () => {
    const [showPwd, setShowPwd] = useState(false)
    const navigate = useNavigate();
    
    return (
        <>
            <div className="float p-[60px] h-screen">
                <div className="float-left w-[762px] h-full">
                    <img className="rounded-2xl h-full w-full" src={`${process.env.PUBLIC_URL}/login-bg.png`} alt="" />
                </div>
                <div className="float-left h-full ml-[58px] flex items-center">
                    <div className="w-full  p-6 bg-white h-fit">
                        <h2 className="text-[32px] font-bold text-center pkb-text-primary">Đăng nhập vào <span className="pkb-primary-color">VPickleball</span></h2>
                        <form className="mt-12">
                            <div>
                                <label className="block text-base pkb-text-primary">Số điện thoại hoặc email</label>
                                <input type="text" placeholder="Nhập email hoặc số điện thoại" className="w-full px-4 py-2 mt-2 border rounded-md pkb-input"/>
                            </div>
                            <div className="mt-8">
                                <label className=" block text-base pkb-text-primary">Mật khẩu</label>
                                <div className="relative">
                                    <input type={!showPwd ? 'password' : 'text'} placeholder="Nhập mật khẩu" className="w-full px-4 py-2 border rounded-md pkb-input"/>
                                        <span className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={() => setShowPwd(!showPwd)}>
                                            { !showPwd ? <EyeOpenIcon /> : <EyeCloseIcon/>}
                                        </span>
                                </div>
                            </div>
                            <div className="mt-4 flex justify-end">
                                <a href="#" className="font-medium text-base pkb-primary-color hover:underline">Quên mật khẩu?</a>
                            </div>
                            <div className="mt-12">
                                <PkbBtn btnTxt={'Đăng nhập'} type="submit" className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"></PkbBtn>
                            </div>
                        </form>
                        <p className="mt-4 text-base text-center pkb-text-primary">
                            Bạn chưa có tài khoản? <a onClick={() => navigate('/register')} className="font-medium pkb-primary-color hover:underline">Đăng ký</a>
                        </p>
                    </div>
                </div>
                <div className="clear-both"></div>
            </div>
        </>
    )
}

export default LoginPage;