import { Pagination } from "@mui/material";
import PkbBreadcumbs from "../../component/common/breadcrumbs";
import PkbFooter from "../../component/footer/footer";
import PkbHeader from "../../component/header/header";
import TournamentLive from "../../component/tournament-live/tournament-live";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from "react-router-dom";
import { UseTournamentList } from "./service";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UseMainBannerImg } from "../home/service";
import PkbBannerSlideshow from "../../component/slideshow/banner-slide";



const Tournament = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { isPending, isError, data, error, refetch } = UseTournamentList();
    const bannerImg = UseMainBannerImg({ page: 1, limit: 99, cid: 8 });
    const [tourClosed, setTourClosed] = useState([])
    const [tourIncoming, setTourIncoming] = useState([])
    const [tourPlaying, setTourPlaying] = useState([])

    const onGoDetail = (id) => {
        navigate(`/tournament/detail/${id}`)
    }

    useEffect(() => {
        const Breadcrumbs = [
            {
                title: 'Trang chủ',
                link: '/home'
            },
            {
                title: 'Giải đấu',
                link: '/tournament'
            }
        ]
        dispatch({ type: 'set', breadcrumbs: Breadcrumbs })
    }, [])

    useEffect(() => {
        if (!data?.results) { return }
        const tournamentClosed = [];
        const tournamentIncoming = [];
        const tournamentPlaying = [];

        data?.results.forEach(item => {
            if (item.status === 1) {
                tournamentClosed.push(item);
                return;
            }

            if (item.status === 2) {
                tournamentPlaying.push(item)
                return;
            }

            tournamentIncoming.push(item)
        })

        setTourClosed(() => [...tournamentClosed])
        setTourIncoming(() => [...tournamentIncoming])
        setTourPlaying(() => [...tournamentPlaying])
    }, [data])
    return (
        <div>
            <div>
                <PkbBannerSlideshow data={bannerImg?.data?.data} imgStyle={{ height: 543 }} size={bannerImg?.data?.data?.length}></PkbBannerSlideshow>
            </div>
            <div className="p-6 absolute top-0 w-full">
                <PkbHeader></PkbHeader>
            </div>
            <div className="pkb-hotnew-bg">
                <div className="pt-16 px-[60px]">
                    <PkbBreadcumbs></PkbBreadcumbs>
                </div>
                <div>
                    {tourPlaying?.length ?
                        <TournamentLive
                            label="Giải đấu đang diễn ra"
                            tailIcon="circle"
                            classes="grid-cols-3"
                            data={tourPlaying}
                            isDetail={true}
                            onClick={(id) => onGoDetail(id)}></TournamentLive>
                        : <></>}
                </div>
                <div>
                    {tourIncoming?.length ?
                        <TournamentLive
                            label="Giải đấu sắp diễn ra"
                            tailCustomIcon={<PlayArrowIcon className="text-white" style={{ width: '100%', height: '100%' }} color="inherit"></PlayArrowIcon>}
                            tailColor="#FCC134"
                            classes="grid-cols-3"
                            footerColor="#FCC134"
                            footerBtnTxt="Đăng ký"
                            footerIcon={<PlayArrowIcon className="text-white" color="inherit"></PlayArrowIcon>}
                            data={tourIncoming}
                            footerTxt="Sắp diễn ra"
                            onClick={(id) => onGoDetail(id)}
                            isDetail={true}></TournamentLive>
                        : <></>}

                </div>

            </div>
            <div>
                {tourClosed?.length ?
                    <TournamentLive
                        label="Giải đấu đã kết thúc"
                        tailIcon="square"
                        tailColor="#919EAB"
                        classes="grid-cols-3"
                        footerColor="#919EAB"
                        footerBtnTxt="Xem kết quả"
                        dataLength={6}
                        showFilter={true}
                        data={tourClosed}
                        onClick={(id) => onGoDetail(id)}
                        footerTxt="Đã kết thúc" isDetail={true}></TournamentLive>
                    : <></>}
            </div>
            <div className="flex justify-center pb-12">
                <Pagination count={10} variant="outlined" shape="rounded" />
            </div>
            <PkbFooter></PkbFooter>
        </div>
    )
}

export default Tournament;