import axios from "axios"

export const baseUrl =  process.env.BASE_URL || 'https://apiv2.vpickleball.com.vn/igb/'
// export const baseUrl =  process.env.BASE_URL || 'http://localhost:4000/igb/'
export const phpBaseUrl =  process.env.PHP_BASE_URL || 'https://apiv1.vpickleball.com.vn/'
export const v1AccessKey = process.env.V1_ACCESS_KEY || 'c074a260a644cecc6f757a35ed1e7511'

export const GET_ARTICLES = 'article'
export const GET_IMAGES = 'image'

export const V1_TOURNAMENT = 'api_hdcp/livescore/listTournament'
export const V1_TOURNAMENT_DETAIL = 'api_hdcp/livescore/tournamentDetail'
export const V1_TOURNAMENT_BOOK = 'api/tournament/book'

export const ajaxGet = async (path, params = {}) => {
    const keys = Object.keys(params)
    keys.forEach(key => {
        if (params[key]) { return }

        delete params[key]
    })

    const response = await axios.get(`${baseUrl}${path}`, {params})
        .then(res => res.data)
        .catch(err => {
            let message = err?.response?.data?.message

            if (typeof message === 'object') {
                message = message.join()
            }

            throw new Error(message)
        })

    return response
}

export const ajaxV1Get = async (path, params = {}) => {
    const keys = Object.keys(params)
    keys.forEach(key => {
        if (params[key]) { return }

        delete params[key]
    })

    params['accessKey'] = v1AccessKey

    const response = await axios.get(`${phpBaseUrl}${path}`, {params})
        .then(res => res.data)
        .catch(err => {
            let message = err?.response?.data?.message

            if (typeof message === 'object') {
                message = message.join()
            }

            throw new Error(message)
        })

    return response
}

export const ajaxV1Post = async (path, body = {}, params = {}) => {
    const keys = Object.keys(params)
    keys.forEach(key => {
        if (params[key]) { return }

        delete params[key]
    })

    params['accessKey'] = v1AccessKey
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    const response = await axios.post(`${phpBaseUrl}${path}`, body, {params, headers})
        .then(res => res.data)
        .catch(err => {
            let message = err?.response?.data?.message

            if (typeof message === 'object') {
                message = message.join()
            }

            throw new Error(message)
        })

    return response
}