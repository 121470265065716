import { Card, CardContent, CardHeader, Chip, Pagination } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import PkbBreadcumbs from "../../component/common/breadcrumbs"
import PkbTab from "../../component/common/tab"
import PkbTailFlag from "../../component/common/tail-flag"
import PkbHeader from "../../component/header/header"
import { UseGetArticleDetail, UseGetArticles } from "./service"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatDateToStr } from "../../share/common"
import PkbPaperIcon from "../../component/common/icon/paper-icon"
import PkbFooter from "../../component/footer/footer"
import { useLocation, useNavigate } from "react-router-dom"
import PkbPaginationTitle from "../../component/common/pagination-title"

const NewDetail = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [newId, setNewId] = useState('');
    const newDetail = UseGetArticleDetail(newId);
    const { isPending, isError, data, error, refetch } = UseGetArticles({ page, limit: 4 });

    useEffect(() => {
        if (!location.pathname) { return }

        const pathName = location.pathname.split('/')
        const id = pathName[pathName.length - 1]

        setNewId(id)
    }, [location])


    useEffect(() => {
        if (!newDetail?.data) { return; }

        const Breadcrumbs = [
            {
                title: 'Trang chủ',
                link: '/home'
            },
            {
                title: 'Tin tức',
                link: '/news'
            },
            {
                title: newDetail?.data?.vn_title,
                link: '/news'
            }
        ]
        dispatch({ type: 'set', breadcrumbs: Breadcrumbs })
    }, [newDetail])

    const goToDetail = (id) => {
        navigate(`/news/${id}`)
    }


    return (
        <div className="pkb-gray-bg">
            <div className="p-6 w-full ">
                <PkbHeader></PkbHeader>
                <div className="pt-16 px-[60px]">
                    <PkbBreadcumbs></PkbBreadcumbs>
                    <div className="grid gap-x-6 pkb-hotnew-column mt-12">
                        <div>
                            <h2 className="text-2xl font-bold">{newDetail?.data?.vn_title}</h2>
                            <div className='text-sm leading-[40px] pkb-text-secondary mb-4'>
                                <AccessTimeIcon></AccessTimeIcon>
                                <span>{formatDateToStr(newDetail?.data?.time_update)}</span>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: newDetail?.data?.vn_detail }}>
                            </p>
                        </div>
                        <div>
                            <Card style={{ borderRadius: '16px' }}>
                                <CardHeader
                                    className="pkb-blue-gradient h-[68px]"
                                    title={
                                        <p>
                                            <span><PkbPaperIcon color="#fff" />
                                            </span>
                                            <span className="text-[32px] font-bold ml-2 text-white">
                                                Tin tức & Sự kiện nổi bật
                                            </span>
                                        </p>
                                    }></CardHeader>
                                <CardContent className="p-4">
                                    {data?.data ? data?.data.map((item, idx) => {
                                        //if (idx === 0) { return null; }
                                        return (
                                            <Card className={'h-fit z-10 mt-4 pkb-primay-border'}>
                                                <CardContent className='grid pkb-hotnew-rightitem-column pkb-8px-padding'>
                                                    <div className="h-32">
                                                        <img src={item.publicPath + item.avatar} alt="" width={128} height={128} className="h-32 w-32" />
                                                    </div>
                                                    <div className='max-h-32 text-left ml-3'>
                                                        <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium cursor-pointer'
                                                            onClick={() => goToDetail(item.id)}>
                                                            {item.vn_title}
                                                        </h1>
                                                        <p className='text-clip max-h-12 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.vn_detail }}>
                                                            {/* {item.vn_detail} */}
                                                        </p>
                                                        <div className='text-sm leading-[40px] pkb-text-secondary'>
                                                            <AccessTimeIcon></AccessTimeIcon>
                                                            <span>{formatDateToStr(item.time_update)}</span>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    }) : <></>}
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className="mb-16 px-[60px]">
                    <div className="float ">
                        <div className="float-left w-9/12 text-left">
                            <p className="text-2xl font-medium">
                                Các tin tức khác
                            </p>
                        </div>
                        <div className="float-right">
                            <PkbPaginationTitle onChange={(value) => {
                                setPage(value ? value : 1)
                            }}
                                dataLength={data?.data?.length || 0}
                                limit={4}
                                page={page}></PkbPaginationTitle>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                    <div className="grid grid-cols-4 gap-x-8 mt-4">
                        {data?.data?.length ?
                            data?.data.map((item, idx) => {
                                //if ((idx + '') === newId) { return; }

                                return (
                                    <Card style={{ borderRadius: '12px' }}>
                                        <CardContent style={{ padding: 0 }}>
                                            <div>
                                                <img src={item.publicPath + item.avatar} style={{ width: '100%', height: '212px' }} />
                                                <div className="p-3">
                                                    <h1 className='text-ellipsis max-h-12 overflow-hidden text-base font-medium cursor-pointer'
                                                        onClick={() => goToDetail(item.id)}>
                                                        {item.vn_title}
                                                    </h1>
                                                    <p className='text-clip max-h-12 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.vn_detail }}>
                                                    </p>
                                                    <div className='text-sm leading-[40px] pkb-text-secondary'>
                                                        <AccessTimeIcon></AccessTimeIcon>
                                                        <span>{formatDateToStr(item.time_update)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                )
                            })
                            : <></>}
                    </div>
                </div>
            </div>
            <PkbFooter></PkbFooter>
        </div>
    )
}

export default NewDetail